import React, { Component, useState, useReducer, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import ReactJson from "react-json-view";

// ------------------------------------------------- firebase 1/3
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";

// ------------------------------------------------- Pages
import Comp_UserProfile from "./Comp_UserProfile";
import Page_CafePC_TotalPC__Hardware from "../Page/Page_CafePC_TotalPC__Hardware";
import Page_CafePC_PCHardware__Cafe from "../Page/Page_CafePC_PCHardware__Cafe";

// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";

// ------------------------------------------------- MUI Dialog 1/
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  makeStyles,
  createMuiTheme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";

import {
  Paper,
  Grid,
  TextField,
  Typography,
  SvgIcon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  AppBar,
  Tabs,
  Tab,
  Box,
  Icon,
  Divider,
  Tooltip,
  Checkbox,
  FormControl,
  FormControlLabel,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  CardMedia,
  Avatar,
} from "@material-ui/core";

import {
  MemoryTwoTone,
  SportsEsportsTwoTone,
  ViewQuilt,
  TableChart,
  StraightenTwoTone,
  SaveAltTwoTone,
  OfflineBoltTwoTone,
  SpeakerTwoTone,
  DesktopWindowsTwoTone,
  KeyboardTwoTone,
  MouseTwoTone,
  HeadsetMicTwoTone,
  AttachMoneyTwoTone,
  PaymentTwoTone,
  ContactSupportTwoTone,
  AddShoppingCartTwoTone,
  VerifiedUserTwoTone,
  EmailTwoTone,
  VpnKeyTwoTone,
  AddToPhotosTwoTone,
  AccountBalanceWalletTwoTone,
  CategoryTwoTone,
  StorefrontTwoTone,
  LocalShippingTwoTone,
  BorderColorTwoTone,
  ContactMailTwoTone,
  AssignmentTwoTone,
  ExtensionTwoTone,
  MonetizationOnTwoTone,
  CheckBoxTwoTone,
  FormatListNumberedRtlTwoTone,
  FiberManualRecord,
  CheckBox,
  BatteryFull,
  BatteryChargingFull,
  BatteryAlert,
  LocalOffer,
  ShoppingCart,
  NotificationImportant,
  Notifications,
  EmojiEmotions,
  ThumbUpAlt,
} from "@material-ui/icons";

// -------------------------------------------------  AutoComplete 1/3
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { MoreVert } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

// test
const handleSubmit = (e, notes, set_notes, input, set_input) => {
  e.preventDefault();
  const id = notes.length ? notes[notes.length - 1].id + 1 : 0;
  set_notes([...notes, { id: id, message: input }]);
  set_input("");
};

const delNotes = (id, notes, set_notes) => {
  set_notes(notes.filter((note) => note.id != id));
};

export default function X({ id2 }) {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // -------------------------------------------------  Context Child 2/3
  const [u_department, setU_department] = useState("N.A");
  const [u_role, setU_role] = useState("N.A");

  // -------------------------------------------------  Variable
  const [patronName, setPatronName] = useState();
  const [patronContact, setPatronContact] = useState();
  const [patronEmail, setPatronEmail] = useState("sample@gmail.com");
  const [patronNote, setPatronNote] = useState();

  const [productQuantity, set_productQuantity] = useState(50);

  //  Get Date from DB -----------------------------------------------------------------------------------------

  const [dbData, setDbData] = useState([]);
  const getData = () => {
    const response1 = axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_golbal_eclipse__careu_hardwareinfo_changeLog__all`,
        { id2 }
      )
      .then((date) => {
        if (id2 === "") {
          setDbData(date.data);
        } else {
          setDbData(
            date.data?.filter((x) => {
              return x.id2 === id2;
            })
          );
        }

        console.log("getData", date.data);
      });
  };

  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Log Datetime",
        field: "insert_dt",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Type",
        field: "update_type",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            {rowData.update_type?.includes("removed") ? (
              <div style={{ color: "red" }}>{rowData.update_type}</div>
            ) : rowData.update_type?.includes("updated") ? (
              <div style={{ color: "orange" }}>{rowData.update_type}</div>
            ) : (
              <div style={{ color: "yellowgreen" }}>{rowData.update_type}</div>
            )}
          </>
        ),
      },
      {
        title: "Category",
        field: "update_path",
        width: "1%",
        headerStyle: {
          minWidth: "160px",
          color: "orange",
        },
        cellStyle: {
          fontSize: 10,
          color: "orange",
        },
        render: (rowData) => (
          <>
            <div>
              {rowData.update_path?.split("||")?.map((x, i) => {
                return <div> {`${i + 1}: ${x} `}</div>;
              })}
            </div>
          </>
        ),
      },
      {
        title: "From",
        field: "update_lhs",
        width: "1%",
        headerStyle: {
          minWidth: "200px",
          color: "orange",
        },
        cellStyle: {
          fontSize: 10,
          color: "orange",
        },
        render: (rowData) => (
          <>
            <div>
              {rowData.update_lhs?.split("||")?.map((x, i) => {
                return <div>{`${i + 1}: ${x !== "undefined" ? x : ""} `}</div>;
              })}
            </div>
          </>
        ),
      },
      {
        title: "To",
        field: "update_rhs",
        width: "1%",
        headerStyle: {
          minWidth: "200px",
          color: "orange",
        },
        cellStyle: {
          fontSize: 10,
          color: "orange",
        },
        render: (rowData) => (
          <>
            <div>
              {rowData.update_rhs?.split("||")?.map((x, i) => {
                return <div>{`${i + 1}: ${x !== "undefined" ? x : ""} `}</div>;
              })}
            </div>
          </>
        ),
      },
      {
        title: "Cafe PC",
        field: "id2",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>{rowData.id2 !== "undefined" ? rowData.id2 : ""}</>
        ),
      },
      {
        title: "Cafe",
        field: "cafe",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>{rowData.cafe !== "undefined" ? rowData.cafe : ""}</>
        ),
      },

      {
        title: "PC",
        field: "pc_name",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>{rowData.pc_name !== "undefined" ? rowData.pc_name : ""}</>
        ),
      },

      {
        title: "PC Info",
        field: "pc_info",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.pc_info} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "pc_info") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "CPU",
        field: "CPU_Name",
        width: "1%",
        headerStyle: {
          minWidth: "150px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            {rowData.CPU_Name !== "undefined" ? (
              <>
                {/* {console.log(
                  'rowData.update_path?.split("||")',
                  rowData.update_path?.split("||")?.map((x, i) => {
                    if (x === "CPU_Name") {
                      return (
                        <>
                          <div>{rowData.update_lhs?.split("||")[i]} </div>
                          ➡️
                          <div style={{ color: "yellowgreen" }}>
                            {rowData.update_rhs?.split("||")[i]}
                          </div>
                        </>
                      );

                      // `${rowData.update_lhs?.split("||")[i]} -> ${
                      //   rowData.update_rhs?.split("||")[i]
                      // }`;
                    }
                  })
                )} */}
                <div>{rowData.CPU_Name} </div>

                {rowData.update_path?.split("||")?.map((x, i) => {
                  if (x?.toString().trim() === "CPU_Name") {
                    return (
                      <>
                        ➡️
                        <div style={{ color: "yellowgreen" }}>
                          {rowData.update_rhs?.split("||")[i]}
                        </div>
                      </>
                    );

                    // `${rowData.update_lhs?.split("||")[i]} -> ${
                    //   rowData.update_rhs?.split("||")[i]
                    // }`;
                  }
                })}
              </>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        title: "GPU",
        field: "GPU_Name",
        width: "1%",
        headerStyle: {
          minWidth: "150px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.GPU_Name} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "GPU_Name") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },

      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.GPU_AdapterRAM} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "GPU_AdapterRAM") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.MOBO_Manufacturer} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "MOBO_Manufacturer") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "MOBO2",
        field: "MOBO_Product",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.MOBO_Product} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "MOBO_Product") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "RAM",
        field: "RAM_Manufacturer",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },

        render: (rowData) => (
          <>
            <div>{rowData.RAM_Manufacturer} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "RAM_Manufacturer") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "RAM2",
        field: "RAM_PartNumber",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.RAM_PartNumber} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "RAM_PartNumber") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "RAM Capacity",
        field: "RAM_Capacity",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.RAM_Capacity} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "RAM_Capacity") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "NIC",
        field: "NIC_InstanceName",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.NIC_InstanceName} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "NIC_InstanceName") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
      {
        title: "MON",
        field: "MON_Manufacturer",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div>{rowData.MON_Manufacturer} </div>
            {rowData.update_path?.split("||")?.map((x, i) => {
              if (x?.toString().trim() === "MON_Manufacturer") {
                return (
                  <>
                    ➡️
                    <div style={{ color: "yellowgreen" }}>
                      {rowData.update_rhs?.split("||")[i]}
                    </div>
                  </>
                );
              }
            })}
          </>
        ),
      },
    ],
  });

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    getData();
  }, []);

  //-------------------------------------------------------------------------- main return()
  return (
    <>
      <div style={{ marginLeft: "2rem" }}>
        <MaterialTable
          title={`Hardware Log (${dbData?.length}) | ${id2} `}
          columns={state.columns}
          data={dbData}
          style={{
            color: "white",
            background: "rgba(0, 0, 0, .3)",
            // backdropFilter: "blur( 11.0px )",
            // "&::webkit-backdrop-filter": {
            //   webkitBackdropFilter: "blur( 18.0px )",
            // },
          }}
          options={{
            exportButton: true,
            headerStyle: {
              background: "rgba(0, 0, 0, .4)",

              border: "none",
            },
            // rowStyle: (rowData) => ({
            //   backgroundColor:
            //     selectedRow === rowData.tableData.id ? "#422400" : "",
            //   fontSize: 12,
            // }),
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.Item_stock_balance < 0
                  ? "#ff0000"
                  : rowData.Item_stock_balance === 0
                  ? "#1a0000"
                  : "",
              color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
              // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
              fontSize: 12,
            }),
            filtering: true,
            // grouping: true,
            exportAllData: true,
            pageSize: 10,
            pageSizeOptions: [20, 50, 100, 200, 500, 1000],
            paginationType: "stepped",
            // actionsColumnIndex: -1
            // fixedColumns: {
            //     left: 1,
            // }
          }}
          // detailPanel={[
          //   {
          //     tooltip: "S43343434",
          //     render: (rowData) => {
          //       return (
          //         <div>
          //           <Page_CafePC_PCHardware__Cafe hardware={rowData.Item} />{" "}
          //         </div>
          //       );
          //     },
          //   },
          // ]}
          // onRowClick={(evt, selectedRow) => {

          // }}
          actions={
            [
              // {
              //   icon: "save",
              //   tooltip: "Save User",
              //   onClick: (event, rowData) => {
              //     // alert("You saved " + rowData.id)
              //     // handleClickOpen_1_1();
              //     // setSelectedRecord_Id(rowData.id)
              //   },
              // },
            ]
          }
          components={
            {
              // Action: (props) => (
              // <Button
              //   onClick={(event) => props.action.onClick(event, props.data)}
              //   color="primary"
              //   variant="contained"
              //   style={{ textTransform: "none" }}
              //   size="small"
              // >
              //   EDIT
              // </Button>
              // ),
            }
          }
        />
      </div>
    </>
  );
}
