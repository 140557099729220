import React, {
  Component,
  useState,
  useReducer,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import axios from "axios";

// ------------------------------------------------- firebase 1/3
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";

// ------------------------------------------------- Pages
import Comp_UserProfile from "../Component/Comp_UserProfile";

import Comp_CafePC_PCHardware_Log from "../Component/Comp_CafePC_PCHardware_Log";

// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";

// ------------------------------------------------- MUI Dialog 1/
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  makeStyles,
  createMuiTheme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";

import { TextField, Typography, Button } from "@material-ui/core";

import { NotificationImportant } from "@material-ui/icons";

// -------------------------------------------------  AutoComplete 1/3
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { MoreVert } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

// test
const handleSubmit = (e, notes, set_notes, input, set_input) => {
  e.preventDefault();
  const id = notes.length ? notes[notes.length - 1].id + 1 : 0;
  set_notes([...notes, { id: id, message: input }]);
  set_input("");
};

const delNotes = (id, notes, set_notes) => {
  set_notes(notes.filter((note) => note.id != id));
};

export default function X(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // -------------------------------------------------  Context Child 2/3
  const [u_department, setU_department] = useState("N.A");
  const [u_role, setU_role] = useState("N.A");

  // -------------------------------------------------  Variable
  const [patronName, setPatronName] = useState();
  const [patronContact, setPatronContact] = useState();
  const [patronEmail, setPatronEmail] = useState("sample@gmail.com");
  const [patronNote, setPatronNote] = useState();

  const [productQuantity, set_productQuantity] = useState(50);

  //  Get Date from DB -----------------------------------------------------------------------------------------

  const [dbData, setDbData] = useState([]);
  const getData = async () => {
    await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_careu_hardwareinfo`)
      .then((date) => {
        setDbData(date.data);
        console.log(date.data);
      });
  };

  const [dbData_Summary, setDbData_Summary] = useState([]);
  const getData_Summary = async () => {
    await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_careu_hardwareinfo_summary`)
      .then((date) => {
        setDbData_Summary(date.data);
        console.log(date.data);
      });
  };

  const [dbData_TotalPC, set_dbData_TotalPC] = useState();
  const [dbData_TotalPC_Length, set_dbData_TotalPC_Length] = useState();
  const getData_TotalPC = () => {
    axios
      .get(`${REACT_APP_ECLIPSE_API}/get_careu_cafe_totalpc`)
      .then((date) => {
        set_dbData_TotalPC(date.data);
        set_dbData_TotalPC_Length(date.data.length);
        // console.log(date.data)
      });
  };

  // delete pc
  const getData_removePC = (_id) => {
    const response1 = axios
      .get(`${REACT_APP_ECLIPSE_API}/delete_careu_hardwareinfo?id=${_id}`)
      .then((date) => {
        // console.log(date.data)
      });
  };

  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      // { title: "sid", field: "id", width: "5%" },
      { title: "no", field: "no", width: "5%" },

      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            {rowData.NIC_Speed.includes("1000") === false &&
            rowData.NIC_Speed.includes("1024") === false ? (
              <>
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        fontSize: "9px",
                        color: "#ff2612",
                        textAlign: "center",
                      }}
                    >
                      <NotificationImportant />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        fontSize: "9px",
                        color: "#ff2612",
                        textAlign: "center",
                      }}
                    >
                      {rowData.NIC_Speed}
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <> {rowData.NIC_Speed}</>
            )}
          </>
        ),
      },

      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "1%",

        headerStyle: {
          maxWidth: "1px",
        },
        render: (rowData) => (
          <>
            {rowData.CPU_VirtualizationFirmwareEnabled !== "True" ? (
              <>
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        fontSize: "9px",
                        color: "#ff2612",
                        textAlign: "center",
                      }}
                    >
                      <NotificationImportant />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        fontSize: "9px",
                        color: "#ff2612",
                        textAlign: "center",
                      }}
                    >
                      {rowData.CPU_VirtualizationFirmwareEnabled}
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <>ON</>
            )}
          </>
        ),
      },
      {
        title: "PC Info",
        field: "pc_info",
        width: "5%",
        cellStyle: {
          fontSize: 8,
        },
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "Start-up Time",
        field: "PC_StartUpTime",
        width: "1%",
        cellStyle: {
          maxWidth: "1px",
        },
        headerStyle: {
          maxWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div>{rowData.PC_StartUpTime.split(".")[0]}</div>
            <div>
              {`${
                parseInt(
                  rowData.PC_StartUpTime.split(".")[0]?.split(":")[1] * 60
                ) +
                parseInt(rowData.PC_StartUpTime.split(".")[0]?.split(":")[2])
              } s`}
            </div>
            <div>
              {parseInt(
                rowData.PC_StartUpTime.split(".")[0]?.split(":")[1] * 60
              ) +
                parseInt(rowData.PC_StartUpTime.split(".")[0]?.split(":")[2]) <=
              60 ? (
                <div style={{ color: "lime" }}>Superman</div>
              ) : parseInt(
                  rowData.PC_StartUpTime.split(".")[0]?.split(":")[1] * 60
                ) +
                  parseInt(
                    rowData.PC_StartUpTime.split(".")[0]?.split(":")[2]
                  ) >
                  60 &&
                parseInt(
                  rowData.PC_StartUpTime.split(".")[0]?.split(":")[1] * 60
                ) +
                  parseInt(
                    rowData.PC_StartUpTime.split(".")[0]?.split(":")[2]
                  ) <=
                  120 ? (
                <div style={{ color: "cyan" }}>Flash</div>
              ) : parseInt(
                  rowData.PC_StartUpTime.split(".")[0]?.split(":")[1] * 60
                ) +
                  parseInt(
                    rowData.PC_StartUpTime.split(".")[0]?.split(":")[2]
                  ) >
                  120 &&
                parseInt(
                  rowData.PC_StartUpTime.split(".")[0]?.split(":")[1] * 60
                ) +
                  parseInt(
                    rowData.PC_StartUpTime.split(".")[0]?.split(":")[2]
                  ) <=
                  240 ? (
                <div style={{ color: "orange" }}>Spiderman</div>
              ) : (
                <div style={{ color: "red" }}>Batman</div>
              )}
            </div>
          </>
        ),
      },
      {
        title: "Offline (Days)",
        field: "OfflineDays",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            {rowData.OfflineDays > 0 ? (
              <>
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        fontSize: "9px",
                        color: "#ff2612",
                        textAlign: "center",
                      }}
                    >
                      <NotificationImportant />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        fontSize: "9px",
                        color: "#ff2612",
                        textAlign: "center",
                      }}
                    >
                      {rowData.OfflineDays + " days"}
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <>{rowData.OfflineDays}</>
            )}
          </>
        ),
      },
      {
        title: "Cafe",
        field: "cafe",
        width: "5%",
        cellStyle: {
          backgroundColor: "#363300",
          color: "#fffbb5",
        },
        headerStyle: {
          backgroundColor: "#363300",
          color: "#fffbb5",
        },
      },

      {
        title: "PC",
        field: "pc_name",
        width: "1%",
        headerStyle: {
          minWidth: "10px",
        },
      },

      {
        title: "CPU",
        field: "CPU_Name",
        width: "5%",

        cellStyle: {
          backgroundColor: "#ADFF2F",
          color: "#000",
        },
        headerStyle: {
          backgroundColor: "#ADFF2F",
          color: "#000",
          minWidth: "150px",
        },
      },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "1%",
        cellStyle: {
          backgroundColor: "#00BFFF",
          color: "#000",
        },
        headerStyle: {
          backgroundColor: "#00BFFF",
          color: "#000",
          minWidth: "150px",
        },
      },

      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "1%",
        cellStyle: {
          color: "#00BFFF",
        },
        headerStyle: {
          color: "#00BFFF",
          minWidth: "1px",
        },
      },
      {
        title: "RAM",
        field: "RAM_Manufacturer",
        width: "1%",
        cellStyle: {
          color: "#000",
          backgroundColor: "#FF69B4",
        },
        headerStyle: {
          color: "#000",
          backgroundColor: "#FF69B4",

          minWidth: "1px",
        },
      },

      {
        title: "RAM Capacity",
        field: "RAM_Capacity",
        width: "1%",
        cellStyle: {
          color: "#000",
          backgroundColor: "#FF69B4",
        },
        headerStyle: {
          color: "#000",
          backgroundColor: "#FF69B4",

          minWidth: "1px",
        },
      },
      {
        title: "MOBO 1",
        field: "MOBO_Product",
        width: "1%",

        cellStyle: {
          backgroundColor: "#8A2BE2",
          color: "#fff",
        },
        headerStyle: {
          backgroundColor: "#8A2BE2",
          color: "#fff",
          minWidth: "60px",
        },
      },
      {
        title: "MOBO 2",
        field: "MOBO_Manufacturer",
        width: "1%",
        cellStyle: {
          color: "#e6e6ff",
        },
        headerStyle: {
          color: "#e6e6ff",
          minWidth: "60px",
        },
      },

      {
        title: "MOBO Ver",
        field: "MOBO_Version",
        width: "1%",
        cellStyle: {
          color: "#e6e6ff",
        },
        headerStyle: {
          color: "#e6e6ff",
          minWidth: "1px",
        },
      },

      {
        title: "MON",
        field: "MON_Manufacturer",
        width: "1%",
        cellStyle: {
          backgroundColor: "#FF4500",
          color: "#fff",
        },
        headerStyle: {
          backgroundColor: "#FF4500",
          color: "#fff",
          minWidth: "1px",
        },
      },
      {
        title: "MON FPS",
        field: "MON_FPS",
        width: "1%",
        cellStyle: {
          backgroundColor: "#FF4500",
          color: "#fff",
        },
        headerStyle: {
          backgroundColor: "#FF4500",
          color: "#fff",
          minWidth: "1px",
        },
      },
      {
        title: "NIC",
        field: "NIC_InstanceName",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
      },

      {
        title: "From",
        field: "LoginFrom",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Until",
        field: "LoginUntil",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Duration (Min) ",
        field: "LoginDuration",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => <>{(rowData.LoginDuration / 60).toFixed(0)}</>,
      },

      {
        title: "Successful Close",
        field: "SuccessfulClose",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Alert",
        field: "Alert",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Desc",
        field: "NoteDesc",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Version",
        field: "ver",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Gen DT",
        field: "id_generate_datetime",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
    ],
    columns_TotalPC: [
      {
        title: "Cafe",
        field: "cafe",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Total PC",
        field: "TotalPC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
    ],
  });

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    getData();
    getData_Summary();
    getData_TotalPC();
  }, []);

  //-------------------------------------------------------------------------- func
  const globalFilter = () => {
    setCafe_name_useState(cafe_name.current);

    let FilterResult = [];

    FilterResult = dbData.filter((x) => {
      // if (
      //   cafe_name.current.trim() !== "" ||
      //   cpu_name.current.trim() === "" ||
      //   gpu_name.current.trim() === "" ||
      //   ram_name.current.trim() === "" ||
      //   ram_size.current.trim() === "" ||
      //   mobo_name.current.trim() === "" ||
      //   mon_name.current.trim() === "" ||
      //   mon_fps.current.trim() === ""
      // ) {
      //   return x?.cafe.toLowerCase().includes(cafe_name.current);
      // }

      let returnResult =
        x?.cafe
          .toLowerCase()
          .trim()
          .includes(cafe_name.current?.toLowerCase().trim()) &&
        x?.CPU_Name.toLowerCase()
          .trim()
          .includes(cpu_name.current?.toLowerCase().trim()) &&
        x?.GPU_Name.toLowerCase()
          .trim()
          .includes(gpu_name.current?.toLowerCase().trim()) &&
        x?.RAM_Manufacturer.toLowerCase()
          .trim()
          .includes(ram_name.current?.toLowerCase().trim()) &&
        x?.RAM_Capacity.toLowerCase()
          .trim()
          .includes(ram_size.current?.toLowerCase().trim()) &&
        x?.MOBO_Product.toLowerCase()
          .trim()
          .includes(mobo_name.current?.toLowerCase().trim()) &&
        x?.MON_Manufacturer.toLowerCase()
          .trim()
          .includes(mon_name.current?.toLowerCase().trim()) &&
        x?.MON_FPS.toLowerCase()
          .trim()
          .includes(mon_fps.current?.toLowerCase().trim());

      return returnResult;
    });

    if (dbData?.length === FilterResult?.length || FilterResult?.length === 0) {
      alert("No Result");
      setCafe_name_useState("No Result");
    }

    setDbData_Filtered(
      FilterResult?.filter((x, i) => {
        x.no = i + 1;

        return true;
      })
    );
  };

  const globalFilter_Reset = () => {
    // cafe_name.current = "";
    // cpu_name.current = "";
    // gpu_name.current = "";
    // ram_name.current = "";
    // ram_size.current = "";
    // mobo_name.current = "";
    // mon_name.current = "";
    // mon_fps.current = "";
    window.location.reload();
  };

  const clearAllData = async () => {
    if (
      window.confirm("All Data will be Remove, redetact pc info required.") ===
      true
    ) {
      if (window.confirm("All Data will be REMOVE wor? sure?") === true) {
        if (
          window.confirm(
            "it will be VERY MAFAN need to wait the pc on back only got data,  sure mou?"
          ) === true
        ) {
          await axios
            .post(`${REACT_APP_ECLIPSE_API}/truncate_careu_hardwareinfo`)
            .then((res) => {
              // window.location.reload();
            });
          alert("done Enjoy");
          window.location.reload();
        }
      }
    }
  };

  //-------------------------------------------------------------------------- glo var
  const [dbData_Filtered, setDbData_Filtered] = useState([]);

  const [cafe_name_useState, setCafe_name_useState] = useState("All Cafe");

  const cafe_name = useRef("");
  const cpu_name = useRef("");
  const gpu_name = useRef("");
  const ram_name = useRef("");
  const ram_size = useRef("");
  const mobo_name = useRef("");
  const mon_name = useRef("");
  const mon_fps = useRef("");

  const focusMe_Ref = useRef(null);

  useEffect(() => {
    focusMe_Ref.current.focus();
  }, []);

  //-------------------------------------------------------------------------- main return()
  return (
    <>
      {/* dbData?.length > 0 */}
      {/* <input ref={focusMe_Ref} type="text" /> */}

      <div className="ContainerFilter">
        <div className="FlexColumn">
          <h2 style={{ color: "gold" }}>Global Filter</h2>
        </div>
        <div className="FlexRow">
          <div className="TB_Filter">
            <TextField
              inputRef={focusMe_Ref}
              inputProps={{
                style: { backgroundColor: "#363300", color: "#fffbb5" },
              }}
              placeholder="Cafe"
              variant="filled"
              onChange={(e) => {
                cafe_name.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#ADFF2F", color: "#000" },
              }}
              placeholder="CPU"
              variant="filled"
              onChange={(e) => {
                cpu_name.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#00BFFF", color: "#000" },
              }}
              placeholder="GPU"
              variant="filled"
              onChange={(e) => {
                gpu_name.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#FF69B4", color: "#000" },
              }}
              placeholder="RAM Name"
              variant="filled"
              onChange={(e) => {
                ram_name.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#FF69B4", color: "#000" },
              }}
              placeholder="RAM Size"
              variant="filled"
              onChange={(e) => {
                ram_size.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#8A2BE2", color: "#fff" },
              }}
              placeholder="Motherboard Name"
              variant="filled"
              onChange={(e) => {
                mobo_name.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#FF4500", color: "#fff" },
              }}
              placeholder="Monitor Name"
              variant="filled"
              onChange={(e) => {
                mon_name.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
          <div className="TB_Filter">
            <TextField
              inputProps={{
                style: { backgroundColor: "#FF4500", color: "#fff" },
              }}
              placeholder="Monitor FPS"
              variant="filled"
              onChange={(e) => {
                mon_fps.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  globalFilter();
                }
              }}
            />
          </div>
        </div>
        <div className="FlexColumn">
          <div className="FlexRow">
            <div className="Btn_Filter">
              <div
                onClick={() => {
                  globalFilter();
                }}
              >
                <div className="FlexRow">
                  {/* <div style={{ fontSize: "18px" }}> */}
                  <div style={{ color: "white" }}>Filtered</div>
                  <div
                    style={{
                      color: "greenyellow",
                      textAlign: "center",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {cafe_name_useState}
                  </div>
                  <div
                    style={{
                      color: "gold",
                      textAlign: "center",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <>
                      {` ${
                        dbData_Filtered?.length > 0
                          ? dbData_Filtered?.length
                          : dbData?.length
                      } PC(s)`}
                    </>
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="FlexRow">
        <div className="Btn_Filter">
          <Button
            size="large"
            variant="outlined"
            onClick={() => {
              getData();
            }}
          >
            Reload Data
          </Button>
        </div>
        <div className="Btn_Filter">
          <Button
            size="large"
            color="secondary"
            variant="outlined"
            onClick={() => {
              globalFilter_Reset();
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="Btn_Filter">
          <Button
            // disabled
            color="secondary"
            size="large"
            variant="outlined"
            onClick={() => {
              clearAllData();
            }}
          >
            Clear All Data and Redetect
          </Button>
        </div>
      </div>
      <br />
      {dbData?.length > 0 ? (
        <>
          <div className="FlexRow" style={{ color: "white" }}>
            <div>
              <h2 style={{ color: "gold" }}> Summary</h2>
              <table className="Table_TAG">
                <tr>
                  <th
                    style={{
                      textAlign: "right",
                      wordWrap: "break-word",
                      color: "gray",
                    }}
                  >
                    #NO
                  </th>
                  <th style={{ textAlign: "left" }}>Cafe</th>
                  <th style={{ textAlign: "right", wordWrap: "break-word" }}>
                    (Total PC)
                  </th>
                  <th style={{ textAlign: "right", wordWrap: "break-word" }}>
                    LAN SLOW! (Total PC)
                  </th>
                  <th style={{ textAlign: "right", wordWrap: "break-word" }}>
                    VT OFF (Total PC)
                  </th>
                  <th style={{ textAlign: "right", wordWrap: "break-word" }}>
                    OFFLINE DAY (Total PC)
                  </th>
                  <th style={{ textAlign: "right", wordWrap: "break-word" }}>
                    OFFLINE RANGE (DAY)
                  </th>
                </tr>
                {dbData_Summary.map((x, i) => {
                  return (
                    <tr
                      className="Table_Row_Hightlight"
                      onClick={() => {
                        cafe_name.current = x?.cafe_X;
                        setCafe_name_useState(x?.cafe_X);
                        globalFilter();
                        cafe_name.current = "";
                      }}
                    >
                      <td style={{ textAlign: "right", color: "gray" }}>{`${
                        i + 1
                      }. `}</td>
                      <td>
                        <div className="Table_Click">{x?.cafe_X}</div>
                      </td>

                      <td style={{ textAlign: "right" }}>{x?.Total_PC_X}</td>

                      <td style={{ textAlign: "right", color: "gold" }}>
                        {x?.Total_PC_Y}
                      </td>

                      <td style={{ textAlign: "right", color: "gold" }}>
                        {x?.Total_PC_Z}
                      </td>

                      <td style={{ textAlign: "right", color: "gold" }}>
                        {x?.Total_PC_Q}
                      </td>
                      {x?.OFFLINE_DAY_RANGE.split("-")[0] > 0 ? (
                        <td
                          style={{ textAlign: "right", backgroundColor: "red" }}
                        >
                          {x?.OFFLINE_DAY_RANGE}
                        </td>
                      ) : (
                        <td style={{ textAlign: "right", color: "gold" }}>
                          {x?.OFFLINE_DAY_RANGE}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <br />

          <MaterialTable
            title={
              <>``
                <h2 style={{ color: "gold" }}>
                  <div>Cafe PC Status </div>
                </h2>
              </>
            }
            columns={state.columns}
            data={dbData_Filtered?.length > 0 ? dbData_Filtered : dbData}
            options={{
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.Item_stock_balance < 0
                    ? "#ff0000"
                    : rowData.Item_stock_balance === 0
                    ? "#1a0000"
                    : "",
                color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
                // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
                fontSize: 12,
              }),
              filtering: true,
              grouping: true,
              exportAllData: true,
              pageSize: 100,
              pageSizeOptions: [100, 200, 500, 1000],
              paginationType: "stepped",
              // actionsColumnIndex: -1
              // fixedColumns: {
              //     left: 1,
              // }
            }}
            onRowClick={(evt, selectedRow) => {
              // console.log(selectedRow.id);

              if (
                window.confirm(
                  `Confirm Delete [ ${selectedRow.cafe} ${selectedRow.pc_name}  ]  ?`
                )
              ) {
                getData_removePC(selectedRow.id);
                getData();
              }
              // setSelected_Item(selectedRow);
              // handleClickOpen_1_1();
              // setSelected_Input_OrderId(selectedRow.order_id);
              // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
              // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
            }}
            detailPanel={[
              {
                tooltip: "S43343434",
                render: (rowData) => {
                  // console.log("rowData", rowData);
                  return (
                    <div>
                      <Comp_CafePC_PCHardware_Log
                        id2={`${rowData?.cafe} ${rowData?.pc_name}`}
                      />
                    </div>
                  );
                },
              },
            ]}
            actions={
              [
                // {
                //   icon: "save",
                //   tooltip: "Save User",
                //   onClick: (event, rowData) => {
                //     // alert("You saved " + rowData.id)
                //     // handleClickOpen_1_1();
                //     // setSelectedRecord_Id(rowData.id)
                //   },
                // },
              ]
            }
            components={
              {
                // Action: (props) => (
                // <Button
                //   onClick={(event) => props.action.onClick(event, props.data)}
                //   color="primary"
                //   variant="contained"
                //   style={{ textTransform: "none" }}
                //   size="small"
                // >
                //   EDIT
                // </Button>
                // ),
              }
            }
          />
        </>
      ) : (
        <div style={{ color: "gold", textAlign: "center" }}>
          <h1>Loading...</h1>
        </div>
      )}
    </>
  );
}
