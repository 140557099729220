import React, { useState, useEffect, useRef, Children } from "react";
import axios from "axios";
// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";
import {
  Table,
  Button as ButtonMT,
  Checkbox,
  Tooltip,
  Spoiler,
  TextInput,
} from "@mantine/core";

import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";

const { REACT_APP_ECLIPSE_API, REACT_APP_ECLIPSE_API_LOCAL } = process.env;

const Page_CafePC_GameDP = () => {
  //  ----------------------------------------------------------------------------------------- database

  const [DB_AllCafeList, setDB_AllCafeList] = useState([]);
  const [DB_AllCafe_GameDP, setBD_AllCafe_GameDP] = useState([]);
  const get_DB_AllCave_GameDP = (dt_from, dt_until) => {
    axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_golbal_eclipse__eclipse_game_dp__lastest`,
        { dt_from, dt_until }
      )
      .then((res) => {
        // setBD_CafeHardware(res.data);

        console.log("DB_AllCafeList", res?.data?.allCafeList);
        console.log("DB_AllCafe_GameDP", res?.data?.result);
        setDB_AllCafeList(res?.data?.allCafeList);
        setBD_AllCafe_GameDP(res?.data?.result);
      });
  };
  //  ----------------------------------------------------------------------------------------- table column
  const [state, setState] = React.useState({
    columns: [
      {
        title: "ID",
        field: "id",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "Game ",
        field: "game_name",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "Category",
        field: "game_category",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "Total",
        field: "grand_total_dp",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "BBA",
        field: "Gift BBA",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "BBK",
        field: "Gift BBK",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "BA",
        field: "Gift BukitAngsana",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "CP",
        field: "Gift CherasPerdana",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "EP",
        field: "Gift EquinePark",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "EVE",
        field: "Gift Evesuite",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "IMP",
        field: "Gift Impian",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "KJ",
        field: "Gift KajangIndah",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "KB",
        field: "Gift Kepong Baru",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "KD",
        field: "Gift KotaDamansara",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "LM",
        field: "Gift LembahMaju",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "MJ",
        field: "Gift Manjalara",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "NIR",
        field: "Gift Nirwana",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "OUG",
        field: "Gift OUG",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "PI",
        field: "Gift PandanIndah",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "SD",
        field: "Gift Serdang",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "SB",
        field: "Gift SGBuloh",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "SM",
        field: "Gift SriManja",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "SL",
        field: "Gift SungaiLong",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "ST",
        field: "Gift Suntek",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "PS",
        field: "Redsea PS",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "PV12",
        field: "Redsea PV12",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "TBR",
        field: "Redsea TBR",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "WM",
        field: "Redsea Wangsa Maju",
        headerStyle: {
          maxWidth: "1px",
        },
      },
    ],
  });
  //  ----------------------------------------------------------------------------------------- func

  function formatDateString(dateString) {
    let date = new Date(dateString);
    let formattedDate =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");
    return formattedDate;
  }

  // const rows = DB_AllCafe_GameDP.map((x) => (
  //   <tr key={x?.id}>
  //     <td>{x?.id}</td>
  //     <td>{x?.game_name}</td>
  //     <td>{x?.game_category}</td>
  //     {DB_AllCafeList?.map((y) => {
  //       return <th>{x?.[y?.cafe]}</th>;
  //     })}
  //   </tr>
  // ));

  //  ----------------------------------------------------------------------------------------- global var
  const [dateRangeValue, setDateRangeValue] = useState([
    // date at 28 days before
    new Date(new Date().setDate(new Date().getDate() - 28)),
    // date at today
    new Date(),
  ]);
  //  ----------------------------------------------------------------------------------------- use effect

  useEffect(() => {
    get_DB_AllCave_GameDP(
      `${formatDateString(dateRangeValue[0])} 00:00:00`,
      `${formatDateString(dateRangeValue[1])} 23:59:59`
    );
  }, []);

  return (
    <div>
      {/* <Table className="table_pcstatus" style={{ backgroundColor: "black" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Game Name</th>
            <th>Category</th>
            {DB_AllCafeList?.map((x) => {
              return <th>{x?.cafe}</th>;
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table> */}
      <div className="FlexRow_Pure">
        <DateRangePicker
          style={{ width: "30%" }}
          label={<div style={{ color: "gold" }}>Date Range</div>}
          placeholder="Pick dates range"
          value={dateRangeValue}
          onChange={setDateRangeValue}
        />
        <ButtonMT
          color="lime"
          size="xl"
          onClick={() => {
            get_DB_AllCave_GameDP(
              `${formatDateString(dateRangeValue[0])} 00:00:00`,
              `${formatDateString(dateRangeValue[1])} 23:59:59`
            );
          }}
        >
          🔍 Search
        </ButtonMT>
      </div>
      <div className="FlexRow_Pure">
        <div style={{ color: "gray" }}>🔍 within</div>
        <ButtonMT
          color="lime"
          variant="subtle"
          size="sm"
          onClick={() => {
            get_DB_AllCave_GameDP(
              `${formatDateString(
                new Date(new Date().setDate(new Date().getDate() - 7 * 4))
              )} 00:00:00`,
              `${formatDateString(new Date())} 23:59:59`
            );
          }}
        >
          4 weeks
        </ButtonMT>
        <ButtonMT
          color="lime"
          variant="subtle"
          size="sm"
          onClick={() => {
            get_DB_AllCave_GameDP(
              `${formatDateString(
                new Date(new Date().setDate(new Date().getDate() - 7 * 4 * 2))
              )} 00:00:00`,
              `${formatDateString(new Date())} 23:59:59`
            );
          }}
        >
          8 weeks
        </ButtonMT>
        <ButtonMT
          color="lime"
          variant="subtle"
          size="sm"
          onClick={() => {
            get_DB_AllCave_GameDP(
              `${formatDateString(
                new Date(new Date().setDate(new Date().getDate() - 7 * 4 * 4))
              )} 00:00:00`,
              `${formatDateString(new Date())} 23:59:59`
            );
          }}
        >
          16 weeks
        </ButtonMT>
        <ButtonMT
          color="lime"
          variant="subtle"
          size="sm"
          onClick={() => {
            get_DB_AllCave_GameDP(
              `${formatDateString(
                new Date(new Date().setDate(new Date().getDate() - 7 * 4 * 8))
              )} 00:00:00`,
              `${formatDateString(new Date())} 23:59:59`
            );
          }}
        >
          32 weeks
        </ButtonMT>
      </div>

      {console.log("dateRangeValue", dateRangeValue)}
      <br />
      <MaterialTable
        title={"Game DP"}
        columns={state.columns}
        data={DB_AllCafe_GameDP}
        style={{
          color: "white",
          background: "rgba(36, 43, 38, .5)",
          // backgroundColor: "rgba(0,0,0,.1)",
          // backgroundColor:
          //   "linear-gradient(90deg,rgba(25, 30, 26,.3) .25%, rgba(0, 0, 0,.5) .25%)",
          // backdropFilter: "blur( 11.0px )",
          // "&::webkit-backdrop-filter": {
          //   webkitBackdropFilter: "blur( 18.0px )",
          // },
        }}
        options={{
          exportButton: true,
          padding: "dense",
          headerStyle: {
            background: "rgba(54, 64, 56,.5)",
            border: "none",
          },
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData?.hide_pc === 1 ? "darkred" : "rgba(25, 30, 26,.5)",

            color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
            // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
            fontSize: 12,
          }),
          filtering: true,
          grouping: true,
          exportAllData: true,
          pageSize: 100,
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
        }}
        onRowClick={(evt, selectedRow) => {
          // console.log(selectedRow.id);
          // setSelected_Item(selectedRow);
          // handleClickOpen_1_1();
          // setSelected_Input_OrderId(selectedRow.order_id);
          // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
          // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
        }}
        detailPanel={
          [
            // {
            //   tooltip: "Show Hardware Changes Log",
            //   render: (rowData) => {
            //     // console.log("rowData", rowData);
            //     return (
            //       <div>
            //         <Comp_CafePC_PCHardware_Log_v2
            //           cafe={rowData?.cafe}
            //           pc_name={rowData?.PC_Name}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]
        }
        actions={
          [
            // {
            //   icon: "save",
            //   tooltip: "Save User",
            //   onClick: (event, rowData) => {
            //     // get_DB_CafeHardware_StatusLog_byCafePCName(
            //     //   rowData?.cafe,
            //     //   rowData?.PC_Name
            //     // );
            //     setSelectedRow(rowData);
            //     setDialog_Show(true);
            //     console.log("rowData", rowData);
            //   },
            // },
          ]
        }
        components={
          {
            // Action: (props) => (
            //   <Button
            //     // disabled
            //     onClick={(event) => props.action.onClick(event, props.data)}
            //     color="inherit"
            //     variant="text"
            //     style={{ textTransform: "none" }}
            //     size="large"
            //   >
            //     <div style={{ fontSize: "2rem" }}>🚧</div>
            //   </Button>
            // ),
          }
        }
      />
    </div>
  );
};

export default Page_CafePC_GameDP;
