import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
} from "react";
import { UserContext } from "../UserContext";
import axios from "axios";

import useFirebase from "../Hooks/useFirebase";
import useDBAuth from "../Hooks/useDBAuth";

// ------------------------------------------------- firebase 1/3
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";

// ------------------------------------------------- Pages
import Comp_UserProfile from "../Component/Comp_UserProfile";

// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";
import Page_Login from "./Page_Login";

// ------------------------------------------------- MUI Dialog 1/
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import {
//   makeStyles,
//   createMuiTheme,
//   useTheme,
//   withStyles,
// } from "@material-ui/core/styles";

// // -------------------------------------------------  AutoComplete 1/3
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
// import Autocomplete, {
//   createFilterOptions,
// } from "@material-ui/lab/Autocomplete";
// import { MoreVert } from "@material-ui/icons";
// import { Alert } from "@material-ui/lab";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

// test
const handleSubmit = (e, notes, set_notes, input, set_input) => {
  e.preventDefault();
  const id = notes.length ? notes[notes.length - 1].id + 1 : 0;
  set_notes([...notes, { id: id, message: input }]);
  set_input("");
};

const delNotes = (id, notes, set_notes) => {
  set_notes(notes.filter((note) => note.id != id));
};

export default function X(props) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);
  // --------------------------------------- auth User
  const [firebase_Status, firebase_SignOut, firebase_LoginUI] = useFirebase();
  const [dbAuth_Status, dbAuth_Login_Obj] = useDBAuth();

  const renderLimit = useRef(2);
  if (firebase_Status?.login_Status === true && renderLimit.current > 0) {
    console.log("P1");
    dbAuth_Login_Obj.u_email = firebase_Status?.userInfo_Email;
    renderLimit.current--;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // -------------------------------------------------  Context Child 2/3
  const [u_department, setU_department] = useState("N.A");
  const [u_role, setU_role] = useState("N.A");

  // -------------------------------------------------  Variable
  const [patronName, setPatronName] = useState();
  const [patronContact, setPatronContact] = useState();
  const [patronEmail, setPatronEmail] = useState("sample@gmail.com");
  const [patronNote, setPatronNote] = useState();

  const [productQuantity, set_productQuantity] = useState(50);

  //  Get Date from DB -----------------------------------------------------------------------------------------

  // 1
  const [dbData, setDbData] = useState();
  const [dbData_Length, setDbData_Length] = useState();
  const getData = () => {
    const response1 = axios
      .get(`${REACT_APP_ECLIPSE_API}/get_careu_hardwareinfo_copy`)
      .then((date) => {
        setDbData(date.data);
        setDbData_Length(date.data.length);
        // console.log(date.data)
      });
  };

  // delete pc
  const getData_removePC = (_id) => {
    const response1 = axios
      .get(`${REACT_APP_ECLIPSE_API}/delete_careu_hardwareinfo?id=${_id}`)
      .then((date) => {
        // console.log(date.data)
      });
  };

  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      // { title: "sid", field: "id", width: "5%" },
      // {
      //   title: "Offline (Days)",
      //   field: "OfflineDays",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      //   render: (rowData) => (
      //     <>
      //       {rowData.OfflineDays > 0 ? (
      //         <>
      //           <div style={{ display: "flex" }}>
      //             <div>
      //               <Typography
      //                 variant="caption"
      //                 display="block"
      //                 style={{
      //                   fontSize: "9px",
      //                   color: "#ff2612",
      //                   textAlign: "center",
      //                 }}
      //               >
      //                 <NotificationImportant />
      //               </Typography>
      //             </div>
      //             <div>
      //               <Typography
      //                 variant="caption"
      //                 display="block"
      //                 style={{
      //                   fontSize: "9px",
      //                   color: "#ff2612",
      //                   textAlign: "center",
      //                 }}
      //               >
      //                 {rowData.OfflineDays + " days"}
      //               </Typography>
      //             </div>
      //           </div>
      //         </>
      //       ) : (
      //         <>{rowData.OfflineDays}</>
      //       )}
      //     </>
      //   ),
      // },

      // {
      //   title: "VT",
      //   field: "CPU_VirtualizationFirmwareEnabled",
      //   width: "1%",

      //   headerStyle: {
      //     maxWidth: "1px",
      //   },
      //   render: (rowData) => (
      //     <>
      //       {rowData.CPU_VirtualizationFirmwareEnabled === "False" ? (
      //         <>
      //           <div style={{ display: "flex" }}>
      //             <div>
      //               <Typography
      //                 variant="caption"
      //                 display="block"
      //                 style={{
      //                   fontSize: "9px",
      //                   color: "#ff2612",
      //                   textAlign: "center",
      //                 }}
      //               >
      //                 <NotificationImportant />
      //               </Typography>
      //             </div>
      //             <div>
      //               <Typography
      //                 variant="caption"
      //                 display="block"
      //                 style={{
      //                   fontSize: "9px",
      //                   color: "#ff2612",
      //                   textAlign: "center",
      //                 }}
      //               >
      //                 {rowData.CPU_VirtualizationFirmwareEnabled}
      //               </Typography>
      //             </div>
      //           </div>
      //         </>
      //       ) : (
      //         <>ON</>
      //       )}
      //     </>
      //   ),
      // },
      {
        title: "id",
        field: "id",
        width: "5%",
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "Cafe",
        field: "cafe",
        width: "5%",
        cellStyle: {
          backgroundColor: "#363300",
          color: "#fffbb5",
        },
        headerStyle: {
          backgroundColor: "#363300",
          color: "#fffbb5",
        },
      },

      {
        title: "PC",
        field: "pc_name",
        width: "1%",
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "PC Info",
        field: "pc_info",
        width: "5%",
        cellStyle: {
          fontSize: 8,
        },
        headerStyle: {
          minWidth: "10px",
        },
      },
      // {
      //   title: "Start-up Time",
      //   field: "PC_StartUpTime",
      //   width: "1%",
      //   cellStyle: {
      //     maxWidth: "1px",
      //   },
      //   headerStyle: {
      //     maxWidth: "1px",
      //   },
      //   render: (rowData) => (
      //     <>
      //       {rowData.PC_StartUpTime !== null
      //         ? rowData.PC_StartUpTime.split(".")[0]
      //         : "n"}
      //     </>
      //   ),
      // },
      {
        title: "CPU",
        field: "CPU_Name",
        width: "5%",
        headerStyle: {
          minWidth: "150px",
        },
      },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "1%",
        headerStyle: {
          minWidth: "150px",
        },
      },

      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
      },
      {
        title: "MOBO 1",
        field: "MOBO_Manufacturer",
        width: "1%",
        headerStyle: {
          minWidth: "60px",
        },
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "1%",
        headerStyle: {
          minWidth: "60px",
        },
      },

      {
        title: "MOBO Ver",
        field: "MOBO_Version",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
      },

      {
        title: "RAM",
        field: "RAM_Manufacturer",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
      },

      {
        title: "RAM Capacity",
        field: "RAM_Capacity",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
      },

      // {
      //   title: "NIC",
      //   field: "NIC_InstanceName",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      // },
      // {
      //   title: "NIC Speed",
      //   field: "NIC_Speed",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      // },

      // {
      //   title: "MON",
      //   field: "MON_Manufacturer",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      // },
      // {
      //   title: "MON FPS",
      //   field: "MON_FPS",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      // },

      // {
      //   title: "From",
      //   field: "LoginFrom",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
      // {
      //   title: "Until",
      //   field: "LoginUntil",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
      // {
      //   title: "Duration (Min) ",
      //   field: "LoginDuration",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   render: (rowData) => <>{(rowData.LoginDuration / 60).toFixed(0)}</>,
      // },

      // {
      //   title: "Successful Close",
      //   field: "SuccessfulClose",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
      // {
      //   title: "Alert",
      //   field: "Alert",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
      // {
      //   title: "Desc",
      //   field: "NoteDesc",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
      // {
      //   title: "Version",
      //   field: "ver",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
      // {
      //   title: "Gen DT",
      //   field: "id_generate_datetime",
      //   width: "1%",
      //   headerStyle: {
      //     minWidth: "1px",
      //   },
      //   cellStyle: {
      //     fontSize: 10,
      //   },
      // },
    ],
  });

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    getData();
  }, []);

  //-------------------------------------------------------------------------- main return()
  return (
    <>
      {firebase_Status?.login_Status === false ? (
        <Page_Login />
      ) : dbAuth_Status?.Auth_Login === true ? (
        <>
          <Comp_UserProfile />

          <MaterialTable
            title={`Cafe PC Info - Backup ( ${dbData_Length} PC) `}
            columns={state.columns}
            data={dbData}
            options={{
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.Item_stock_balance < 0
                    ? "#ff0000"
                    : rowData.Item_stock_balance === 0
                    ? "#1a0000"
                    : "",
                color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
                // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
                fontSize: 12,
              }),
              filtering: true,
              grouping: true,
              exportAllData: true,
              pageSize: 100,
              pageSizeOptions: [100, 200, 500, 1000],
              paginationType: "stepped",
              // actionsColumnIndex: -1
              // fixedColumns: {
              //     left: 1,
              // }
            }}
            onRowClick={(evt, selectedRow) => {
              // console.log(selectedRow.id);
              // setSelected_Item(selectedRow);
              // handleClickOpen_1_1();
              // setSelected_Input_OrderId(selectedRow.order_id);
              // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
              // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
            }}
            actions={
              [
                // {
                //   icon: "save",
                //   tooltip: "Save User",
                //   onClick: (event, rowData) => {
                //     // alert("You saved " + rowData.id)
                //     // handleClickOpen_1_1();
                //     // setSelectedRecord_Id(rowData.id)
                //   },
                // },
              ]
            }
            components={
              {
                // Action: (props) => (
                // <Button
                //   onClick={(event) => props.action.onClick(event, props.data)}
                //   color="primary"
                //   variant="contained"
                //   style={{ textTransform: "none" }}
                //   size="small"
                // >
                //   EDIT
                // </Button>
                // ),
              }
            }
          />
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
}
