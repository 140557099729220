import React, { Component, useState, useReducer, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

// ------------------------------------------------- firebase 1/3
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";

// ------------------------------------------------- Pages
import Comp_UserProfile from "../Component/Comp_UserProfile";

// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";

// ------------------------------------------------- MUI Dialog 1/
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  makeStyles,
  createMuiTheme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";

import {
  Paper,
  Grid,
  TextField,
  Typography,
  SvgIcon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  AppBar,
  Tabs,
  Tab,
  Box,
  Icon,
  Divider,
  Tooltip,
  Checkbox,
  FormControl,
  FormControlLabel,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  CardMedia,
  Avatar,
} from "@material-ui/core";

import {
  MemoryTwoTone,
  SportsEsportsTwoTone,
  ViewQuilt,
  TableChart,
  StraightenTwoTone,
  SaveAltTwoTone,
  OfflineBoltTwoTone,
  SpeakerTwoTone,
  DesktopWindowsTwoTone,
  KeyboardTwoTone,
  MouseTwoTone,
  HeadsetMicTwoTone,
  AttachMoneyTwoTone,
  PaymentTwoTone,
  ContactSupportTwoTone,
  AddShoppingCartTwoTone,
  VerifiedUserTwoTone,
  EmailTwoTone,
  VpnKeyTwoTone,
  AddToPhotosTwoTone,
  AccountBalanceWalletTwoTone,
  CategoryTwoTone,
  StorefrontTwoTone,
  LocalShippingTwoTone,
  BorderColorTwoTone,
  ContactMailTwoTone,
  AssignmentTwoTone,
  ExtensionTwoTone,
  MonetizationOnTwoTone,
  CheckBoxTwoTone,
  FormatListNumberedRtlTwoTone,
  FiberManualRecord,
  CheckBox,
  BatteryFull,
  BatteryChargingFull,
  BatteryAlert,
  LocalOffer,
  ShoppingCart,
  NotificationImportant,
  Notifications,
  EmojiEmotions,
  ThumbUpAlt,
} from "@material-ui/icons";

// -------------------------------------------------  AutoComplete 1/3
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { MoreVert } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

// test
const handleSubmit = (e, notes, set_notes, input, set_input) => {
  e.preventDefault();
  const id = notes.length ? notes[notes.length - 1].id + 1 : 0;
  set_notes([...notes, { id: id, message: input }]);
  set_input("");
};

const delNotes = (id, notes, set_notes) => {
  set_notes(notes.filter((note) => note.id != id));
};

export default function X({ cafe, hardware }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // -------------------------------------------------  Context Child 2/3
  const [u_department, setU_department] = useState("N.A");
  const [u_role, setU_role] = useState("N.A");

  // -------------------------------------------------  Variable
  const [patronName, setPatronName] = useState();
  const [patronContact, setPatronContact] = useState();
  const [patronEmail, setPatronEmail] = useState("sample@gmail.com");
  const [patronNote, setPatronNote] = useState();

  const [productQuantity, set_productQuantity] = useState(50);

  //  Get Date from DB -----------------------------------------------------------------------------------------

  const [dbData, setDbData] = useState();
  const [dbData_Length, setDbData_Length] = useState();
  const getData = (_cafe, _hardware) => {
    const response1 = axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_careu_cafe_cafeby_hardware__PC?cafe=${_cafe}&hardware=${_hardware}`
      )
      .then((date) => {
        setDbData(date.data);
        setDbData_Length(date.data.length);
        // console.log("cafe-hw-pc", date.data);
      });
  };

  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Cafe",
        field: "cafe",
        width: "20%",
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "pc_name",
        field: "pc_name",
        width: "1%",
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "Item",
        field: "Item",
        width: "50%",
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "Total",
        field: "Total",
        width: "1%",
        headerStyle: {
          minWidth: "10px",
        },
      },
    ],
  });

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    getData(cafe, hardware);
    // console.log("hardware", "|" + hardware + "|");
    // console.log("cafe", "|" + cafe + "|");
  }, []);

  //-------------------------------------------------------------------------- main return()
  return (
    <>
      <MaterialTable
        style={{ marginLeft: 100 }}
        title={`${cafe} - Total PC  ( ${dbData_Length} ) `}
        columns={state.columns}
        data={dbData}
        options={{
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.Item_stock_balance < 0
                ? "#ff0000"
                : rowData.Item_stock_balance === 0
                ? "#1a0000"
                : "",
            color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
            // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          exportAllData: true,
          pageSize: 20,
          pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
        }}
        // detailPanel={[
        //   {
        //     tooltip: "S43343434",
        //     render: (rowData) => {
        //       return (
        //         <div>{/* <Statistic_Item_Table item_id={rowData.id} /> */}</div>
        //       );
        //     },
        //   },
        // ]}
        // onRowClick={(evt, selectedRow) => {

        // }}
        actions={
          [
            // {
            //   icon: "save",
            //   tooltip: "Save User",
            //   onClick: (event, rowData) => {
            //     // alert("You saved " + rowData.id)
            //     // handleClickOpen_1_1();
            //     // setSelectedRecord_Id(rowData.id)
            //   },
            // },
          ]
        }
        components={
          {
            // Action: (props) => (
            // <Button
            //   onClick={(event) => props.action.onClick(event, props.data)}
            //   color="primary"
            //   variant="contained"
            //   style={{ textTransform: "none" }}
            //   size="small"
            // >
            //   EDIT
            // </Button>
            // ),
          }
        }
      />
    </>
  );
}
