import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Collapse } from "@mantine/core";

import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveCalendar } from "@nivo/calendar";
const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

const Chart_Calendar = ({ cafe }) => {
  const [db_Data, setDb_Data] = useState([]);
  const get_DB_Data = async (cafe) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__allcafepc__calendarstatus`,
        {
          cafe,
        }
      )
      .then((res) => {
        setDb_Data(res.data);

        console.log("get_DB_Data", res.data);
      });
  };

  const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (opened) {
      get_DB_Data(cafe);
      console.log("p cafe", cafe);
    }
  }, [cafe, opened]);

  let data2 = [
    {
      value: 338,
      day: "2015-04-22",
    },
    {
      value: 224,
      day: "2015-09-12",
    },
    {
      value: 54,
      day: "2015-04-21",
    },
    {
      value: 216,
      day: "2015-09-10",
    },
    {
      value: 110,
      day: "2015-04-26",
    },
    {
      value: 2,
      day: "2016-01-02",
    },
    {
      value: 183,
      day: "2016-12-10",
    },
    {
      value: 347,
      day: "2017-08-03",
    },
    {
      value: 93,
      day: "2017-05-06",
    },
    {
      value: 317,
      day: "2015-08-15",
    },
    {
      value: 302,
      day: "2016-07-02",
    },
    {
      value: 382,
      day: "2015-05-08",
    },
    {
      value: 345,
      day: "2018-07-31",
    },
    {
      value: 388,
      day: "2018-07-17",
    },
    {
      value: 5,
      day: "2016-07-18",
    },
    {
      value: 372,
      day: "2016-10-20",
    },
    {
      value: 343,
      day: "2017-03-22",
    },
    {
      value: 65,
      day: "2017-02-19",
    },
    {
      value: 231,
      day: "2017-09-15",
    },
    {
      value: 118,
      day: "2017-09-25",
    },
    {
      value: 277,
      day: "2015-12-30",
    },
    {
      value: 9,
      day: "2016-02-16",
    },
    {
      value: 316,
      day: "2016-12-16",
    },
    {
      value: 56,
      day: "2016-09-27",
    },
    {
      value: 76,
      day: "2017-03-02",
    },
    {
      value: 7,
      day: "2017-04-22",
    },
    {
      value: 318,
      day: "2015-10-03",
    },
    {
      value: 372,
      day: "2017-01-09",
    },
    {
      value: 311,
      day: "2018-04-25",
    },
    {
      value: 173,
      day: "2018-07-11",
    },
    {
      value: 329,
      day: "2017-05-23",
    },
    {
      value: 352,
      day: "2017-01-01",
    },
    {
      value: 62,
      day: "2017-08-22",
    },
    {
      value: 304,
      day: "2018-04-03",
    },
    {
      value: 254,
      day: "2016-04-28",
    },
    {
      value: 348,
      day: "2018-04-09",
    },
    {
      value: 13,
      day: "2016-09-04",
    },
    {
      value: 75,
      day: "2017-09-14",
    },
    {
      value: 94,
      day: "2017-09-11",
    },
    {
      value: 310,
      day: "2016-07-23",
    },
    {
      value: 176,
      day: "2018-02-02",
    },
    {
      value: 378,
      day: "2016-08-03",
    },
    {
      value: 124,
      day: "2015-12-05",
    },
    {
      value: 340,
      day: "2016-02-27",
    },
    {
      value: 10,
      day: "2015-06-30",
    },
    {
      value: 362,
      day: "2017-12-03",
    },
    {
      value: 252,
      day: "2015-04-28",
    },
    {
      value: 141,
      day: "2015-05-27",
    },
    {
      value: 167,
      day: "2017-09-23",
    },
    {
      value: 271,
      day: "2018-01-08",
    },
    {
      value: 381,
      day: "2015-12-28",
    },
    {
      value: 97,
      day: "2016-03-28",
    },
    {
      value: 98,
      day: "2017-11-14",
    },
    {
      value: 68,
      day: "2017-11-25",
    },
    {
      value: 181,
      day: "2017-10-29",
    },
    {
      value: 322,
      day: "2017-07-22",
    },
    {
      value: 249,
      day: "2016-08-02",
    },
    {
      value: 266,
      day: "2016-05-14",
    },
    {
      value: 119,
      day: "2017-10-24",
    },
    {
      value: 107,
      day: "2016-09-22",
    },
    {
      value: 251,
      day: "2016-05-10",
    },
    {
      value: 340,
      day: "2017-06-29",
    },
    {
      value: 55,
      day: "2016-12-11",
    },
    {
      value: 253,
      day: "2015-12-06",
    },
    {
      value: 133,
      day: "2016-03-13",
    },
    {
      value: 206,
      day: "2015-05-10",
    },
    {
      value: 330,
      day: "2018-06-20",
    },
    {
      value: 367,
      day: "2016-03-03",
    },
    {
      value: 88,
      day: "2016-09-19",
    },
    {
      value: 313,
      day: "2018-06-05",
    },
    {
      value: 216,
      day: "2016-11-10",
    },
    {
      value: 290,
      day: "2018-04-07",
    },
    {
      value: 275,
      day: "2015-04-27",
    },
    {
      value: 391,
      day: "2015-06-25",
    },
    {
      value: 139,
      day: "2018-01-16",
    },
    {
      value: 342,
      day: "2016-04-08",
    },
    {
      value: 264,
      day: "2017-07-25",
    },
    {
      value: 158,
      day: "2018-07-23",
    },
    {
      value: 282,
      day: "2017-06-12",
    },
    {
      value: 89,
      day: "2016-11-20",
    },
    {
      value: 56,
      day: "2017-09-21",
    },
    {
      value: 212,
      day: "2018-01-04",
    },
    {
      value: 312,
      day: "2015-11-15",
    },
    {
      value: 280,
      day: "2017-08-19",
    },
    {
      value: 282,
      day: "2016-10-25",
    },
    {
      value: 134,
      day: "2016-10-03",
    },
    {
      value: 98,
      day: "2017-02-01",
    },
    {
      value: 269,
      day: "2017-01-16",
    },
    {
      value: 307,
      day: "2017-02-24",
    },
    {
      value: 228,
      day: "2015-08-31",
    },
    {
      value: 209,
      day: "2017-01-11",
    },
    {
      value: 6,
      day: "2015-04-14",
    },
    {
      value: 385,
      day: "2015-06-06",
    },
    {
      value: 146,
      day: "2015-08-30",
    },
    {
      value: 27,
      day: "2018-07-20",
    },
    {
      value: 111,
      day: "2018-03-13",
    },
    {
      value: 257,
      day: "2016-02-22",
    },
    {
      value: 311,
      day: "2016-05-21",
    },
    {
      value: 267,
      day: "2018-04-15",
    },
    {
      value: 98,
      day: "2018-01-25",
    },
    {
      value: 50,
      day: "2015-10-15",
    },
    {
      value: 134,
      day: "2017-04-19",
    },
    {
      value: 12,
      day: "2017-07-12",
    },
    {
      value: 269,
      day: "2015-09-15",
    },
    {
      value: 186,
      day: "2015-07-09",
    },
    {
      value: 172,
      day: "2016-06-24",
    },
    {
      value: 175,
      day: "2016-05-02",
    },
    {
      value: 355,
      day: "2017-09-13",
    },
    {
      value: 247,
      day: "2018-03-03",
    },
    {
      value: 372,
      day: "2017-10-17",
    },
    {
      value: 152,
      day: "2015-08-26",
    },
    {
      value: 142,
      day: "2016-02-07",
    },
    {
      value: 100,
      day: "2018-05-15",
    },
    {
      value: 264,
      day: "2016-08-29",
    },
    {
      value: 307,
      day: "2015-04-24",
    },
    {
      value: 209,
      day: "2018-01-01",
    },
    {
      value: 311,
      day: "2015-05-18",
    },
    {
      value: 48,
      day: "2016-10-16",
    },
    {
      value: 6,
      day: "2016-11-16",
    },
    {
      value: 174,
      day: "2016-01-12",
    },
    {
      value: 81,
      day: "2016-07-09",
    },
    {
      value: 368,
      day: "2016-02-23",
    },
    {
      value: 185,
      day: "2016-04-10",
    },
    {
      value: 358,
      day: "2017-08-16",
    },
    {
      value: 51,
      day: "2016-05-25",
    },
    {
      value: 357,
      day: "2017-02-04",
    },
    {
      value: 47,
      day: "2016-02-29",
    },
    {
      value: 232,
      day: "2017-05-13",
    },
    {
      value: 199,
      day: "2017-10-09",
    },
    {
      value: 255,
      day: "2015-08-01",
    },
    {
      value: 397,
      day: "2017-10-27",
    },
    {
      value: 53,
      day: "2015-04-17",
    },
    {
      value: 386,
      day: "2016-10-12",
    },
    {
      value: 251,
      day: "2017-08-06",
    },
    {
      value: 115,
      day: "2017-08-23",
    },
    {
      value: 111,
      day: "2017-11-11",
    },
    {
      value: 352,
      day: "2018-03-19",
    },
    {
      value: 21,
      day: "2017-04-12",
    },
    {
      value: 333,
      day: "2015-12-15",
    },
    {
      value: 14,
      day: "2016-07-20",
    },
    {
      value: 135,
      day: "2016-06-25",
    },
    {
      value: 34,
      day: "2016-10-28",
    },
    {
      value: 82,
      day: "2016-01-13",
    },
    {
      value: 77,
      day: "2016-01-31",
    },
    {
      value: 302,
      day: "2016-01-09",
    },
    {
      value: 370,
      day: "2016-01-11",
    },
    {
      value: 98,
      day: "2016-03-23",
    },
    {
      value: 191,
      day: "2015-05-19",
    },
    {
      value: 363,
      day: "2015-05-28",
    },
    {
      value: 20,
      day: "2015-05-04",
    },
    {
      value: 255,
      day: "2018-05-08",
    },
    {
      value: 312,
      day: "2018-01-07",
    },
    {
      value: 167,
      day: "2016-06-15",
    },
    {
      value: 219,
      day: "2018-05-26",
    },
    {
      value: 259,
      day: "2018-01-22",
    },
    {
      value: 231,
      day: "2016-07-12",
    },
    {
      value: 46,
      day: "2015-10-19",
    },
    {
      value: 89,
      day: "2015-10-21",
    },
    {
      value: 41,
      day: "2018-03-16",
    },
    {
      value: 337,
      day: "2015-10-12",
    },
    {
      value: 286,
      day: "2017-07-29",
    },
    {
      value: 38,
      day: "2016-02-06",
    },
    {
      value: 195,
      day: "2018-08-04",
    },
    {
      value: 227,
      day: "2016-02-12",
    },
    {
      value: 108,
      day: "2018-02-03",
    },
    {
      value: 281,
      day: "2017-05-27",
    },
    {
      value: 67,
      day: "2018-02-19",
    },
    {
      value: 51,
      day: "2015-07-05",
    },
    {
      value: 235,
      day: "2018-02-11",
    },
    {
      value: 88,
      day: "2016-03-10",
    },
    {
      value: 136,
      day: "2016-01-26",
    },
    {
      value: 44,
      day: "2017-07-05",
    },
    {
      value: 242,
      day: "2016-08-27",
    },
    {
      value: 89,
      day: "2017-03-21",
    },
    {
      value: 60,
      day: "2017-08-14",
    },
    {
      value: 63,
      day: "2016-03-12",
    },
    {
      value: 313,
      day: "2015-11-16",
    },
    {
      value: 135,
      day: "2016-10-15",
    },
    {
      value: 99,
      day: "2017-06-01",
    },
    {
      value: 307,
      day: "2017-08-30",
    },
    {
      value: 192,
      day: "2017-02-06",
    },
    {
      value: 3,
      day: "2017-01-17",
    },
    {
      value: 222,
      day: "2017-04-11",
    },
    {
      value: 308,
      day: "2015-06-26",
    },
    {
      value: 356,
      day: "2017-09-07",
    },
    {
      value: 225,
      day: "2018-02-12",
    },
    {
      value: 131,
      day: "2015-08-07",
    },
    {
      value: 299,
      day: "2015-04-20",
    },
    {
      value: 26,
      day: "2015-07-02",
    },
    {
      value: 33,
      day: "2015-05-20",
    },
    {
      value: 346,
      day: "2015-11-26",
    },
    {
      value: 319,
      day: "2016-03-08",
    },
    {
      value: 201,
      day: "2015-10-04",
    },
    {
      value: 141,
      day: "2016-07-24",
    },
    {
      value: 139,
      day: "2016-06-16",
    },
    {
      value: 150,
      day: "2016-03-02",
    },
    {
      value: 271,
      day: "2018-06-29",
    },
    {
      value: 367,
      day: "2016-07-05",
    },
    {
      value: 99,
      day: "2015-09-05",
    },
    {
      value: 246,
      day: "2017-04-01",
    },
    {
      value: 232,
      day: "2016-09-17",
    },
    {
      value: 2,
      day: "2018-06-08",
    },
    {
      value: 328,
      day: "2015-11-04",
    },
    {
      value: 64,
      day: "2015-09-09",
    },
    {
      value: 297,
      day: "2016-05-23",
    },
    {
      value: 348,
      day: "2016-01-27",
    },
    {
      value: 283,
      day: "2018-08-06",
    },
    {
      value: 186,
      day: "2015-04-02",
    },
    {
      value: 303,
      day: "2018-04-27",
    },
    {
      value: 287,
      day: "2015-08-25",
    },
    {
      value: 226,
      day: "2017-06-02",
    },
    {
      value: 58,
      day: "2016-03-25",
    },
    {
      value: 7,
      day: "2018-01-26",
    },
    {
      value: 40,
      day: "2016-11-11",
    },
    {
      value: 216,
      day: "2016-08-19",
    },
    {
      value: 102,
      day: "2016-12-23",
    },
    {
      value: 243,
      day: "2015-07-17",
    },
    {
      value: 111,
      day: "2017-11-18",
    },
    {
      value: 222,
      day: "2017-02-09",
    },
    {
      value: 362,
      day: "2016-10-24",
    },
    {
      value: 239,
      day: "2017-07-09",
    },
    {
      value: 110,
      day: "2016-03-06",
    },
    {
      value: 14,
      day: "2018-05-04",
    },
    {
      value: 302,
      day: "2016-05-05",
    },
    {
      value: 347,
      day: "2017-10-19",
    },
    {
      value: 370,
      day: "2017-04-03",
    },
    {
      value: 95,
      day: "2016-12-27",
    },
    {
      value: 124,
      day: "2017-05-14",
    },
    {
      value: 62,
      day: "2018-06-06",
    },
    {
      value: 306,
      day: "2015-07-25",
    },
    {
      value: 30,
      day: "2018-04-13",
    },
    {
      value: 394,
      day: "2015-07-29",
    },
    {
      value: 372,
      day: "2016-08-17",
    },
    {
      value: 320,
      day: "2016-08-01",
    },
    {
      value: 161,
      day: "2016-09-24",
    },
    {
      value: 368,
      day: "2016-07-19",
    },
    {
      value: 204,
      day: "2018-02-21",
    },
    {
      value: 157,
      day: "2018-03-23",
    },
    {
      value: 248,
      day: "2017-10-08",
    },
    {
      value: 171,
      day: "2018-03-08",
    },
    {
      value: 289,
      day: "2018-07-22",
    },
    {
      value: 312,
      day: "2015-04-15",
    },
    {
      value: 8,
      day: "2016-04-27",
    },
    {
      value: 355,
      day: "2018-07-19",
    },
    {
      value: 25,
      day: "2017-06-11",
    },
    {
      value: 204,
      day: "2018-04-21",
    },
    {
      value: 340,
      day: "2015-11-30",
    },
    {
      value: 3,
      day: "2016-11-27",
    },
    {
      value: 85,
      day: "2017-11-28",
    },
    {
      value: 291,
      day: "2016-08-05",
    },
    {
      value: 394,
      day: "2018-06-10",
    },
    {
      value: 394,
      day: "2017-07-27",
    },
    {
      value: 258,
      day: "2015-10-06",
    },
    {
      value: 44,
      day: "2018-07-06",
    },
    {
      value: 249,
      day: "2018-05-12",
    },
    {
      value: 261,
      day: "2018-05-25",
    },
    {
      value: 94,
      day: "2015-09-13",
    },
    {
      value: 227,
      day: "2015-08-23",
    },
    {
      value: 156,
      day: "2015-12-10",
    },
    {
      value: 201,
      day: "2017-04-24",
    },
    {
      value: 256,
      day: "2017-04-08",
    },
    {
      value: 137,
      day: "2017-12-05",
    },
    {
      value: 325,
      day: "2016-06-22",
    },
    {
      value: 246,
      day: "2017-01-13",
    },
    {
      value: 187,
      day: "2017-06-13",
    },
    {
      value: 215,
      day: "2015-08-22",
    },
    {
      value: 71,
      day: "2017-08-15",
    },
    {
      value: 192,
      day: "2017-12-11",
    },
    {
      value: 126,
      day: "2015-06-22",
    },
    {
      value: 283,
      day: "2018-03-02",
    },
    {
      value: 23,
      day: "2015-07-04",
    },
    {
      value: 73,
      day: "2015-05-26",
    },
    {
      value: 178,
      day: "2018-04-22",
    },
    {
      value: 327,
      day: "2017-03-04",
    },
    {
      value: 57,
      day: "2015-05-12",
    },
    {
      value: 230,
      day: "2018-06-04",
    },
    {
      value: 217,
      day: "2017-12-30",
    },
    {
      value: 19,
      day: "2015-06-15",
    },
    {
      value: 83,
      day: "2018-01-21",
    },
    {
      value: 128,
      day: "2018-03-09",
    },
    {
      value: 51,
      day: "2018-02-13",
    },
    {
      value: 62,
      day: "2016-07-17",
    },
    {
      value: 279,
      day: "2016-09-20",
    },
    {
      value: 54,
      day: "2018-01-23",
    },
    {
      value: 327,
      day: "2017-03-26",
    },
    {
      value: 23,
      day: "2016-02-14",
    },
    {
      value: 153,
      day: "2017-05-15",
    },
    {
      value: 137,
      day: "2017-12-21",
    },
    {
      value: 115,
      day: "2017-05-18",
    },
    {
      value: 348,
      day: "2015-07-28",
    },
    {
      value: 197,
      day: "2018-03-11",
    },
    {
      value: 118,
      day: "2017-02-03",
    },
    {
      value: 280,
      day: "2016-09-28",
    },
    {
      value: 393,
      day: "2015-04-19",
    },
    {
      value: 191,
      day: "2016-12-13",
    },
    {
      value: 280,
      day: "2015-07-23",
    },
    {
      value: 203,
      day: "2016-09-07",
    },
    {
      value: 90,
      day: "2016-08-06",
    },
    {
      value: 378,
      day: "2017-04-30",
    },
    {
      value: 186,
      day: "2016-01-07",
    },
    {
      value: 229,
      day: "2018-03-07",
    },
    {
      value: 346,
      day: "2016-02-08",
    },
    {
      value: 58,
      day: "2018-03-25",
    },
    {
      value: 361,
      day: "2015-12-14",
    },
    {
      value: 249,
      day: "2018-01-03",
    },
    {
      value: 300,
      day: "2015-12-20",
    },
    {
      value: 145,
      day: "2015-09-27",
    },
    {
      value: 77,
      day: "2017-04-25",
    },
    {
      value: 190,
      day: "2018-01-15",
    },
    {
      value: 280,
      day: "2015-08-21",
    },
    {
      value: 35,
      day: "2016-12-03",
    },
    {
      value: 121,
      day: "2015-10-07",
    },
    {
      value: 193,
      day: "2016-06-02",
    },
    {
      value: 297,
      day: "2017-11-13",
    },
    {
      value: 131,
      day: "2015-08-03",
    },
    {
      value: 228,
      day: "2018-05-28",
    },
    {
      value: 55,
      day: "2016-12-08",
    },
    {
      value: 117,
      day: "2018-05-06",
    },
    {
      value: 246,
      day: "2018-06-30",
    },
    {
      value: 293,
      day: "2017-09-04",
    },
    {
      value: 310,
      day: "2016-11-02",
    },
    {
      value: 177,
      day: "2018-06-01",
    },
    {
      value: 5,
      day: "2017-08-12",
    },
    {
      value: 69,
      day: "2017-02-13",
    },
    {
      value: 97,
      day: "2017-03-05",
    },
    {
      value: 104,
      day: "2017-06-16",
    },
    {
      value: 261,
      day: "2015-05-05",
    },
    {
      value: 181,
      day: "2018-05-31",
    },
    {
      value: 152,
      day: "2016-04-22",
    },
    {
      value: 383,
      day: "2016-03-16",
    },
    {
      value: 2,
      day: "2017-11-15",
    },
    {
      value: 106,
      day: "2016-06-13",
    },
    {
      value: 11,
      day: "2017-12-14",
    },
    {
      value: 240,
      day: "2016-11-08",
    },
    {
      value: 199,
      day: "2016-11-25",
    },
    {
      value: 327,
      day: "2015-10-16",
    },
    {
      value: 39,
      day: "2015-05-30",
    },
    {
      value: 390,
      day: "2018-05-14",
    },
    {
      value: 296,
      day: "2017-09-27",
    },
    {
      value: 218,
      day: "2015-07-24",
    },
    {
      value: 66,
      day: "2017-06-14",
    },
    {
      value: 181,
      day: "2017-09-20",
    },
    {
      value: 317,
      day: "2017-03-30",
    },
    {
      value: 378,
      day: "2017-11-09",
    },
    {
      value: 131,
      day: "2015-11-10",
    },
    {
      value: 334,
      day: "2015-11-06",
    },
    {
      value: 332,
      day: "2017-10-14",
    },
    {
      value: 94,
      day: "2016-10-08",
    },
    {
      value: 65,
      day: "2017-02-10",
    },
    {
      value: 273,
      day: "2016-04-21",
    },
    {
      value: 123,
      day: "2017-03-11",
    },
    {
      value: 93,
      day: "2016-06-03",
    },
    {
      value: 294,
      day: "2015-09-26",
    },
    {
      value: 159,
      day: "2015-04-18",
    },
    {
      value: 131,
      day: "2017-05-08",
    },
    {
      value: 181,
      day: "2017-08-24",
    },
    {
      value: 326,
      day: "2016-09-09",
    },
    {
      value: 213,
      day: "2015-04-04",
    },
    {
      value: 208,
      day: "2016-05-19",
    },
    {
      value: 78,
      day: "2015-05-02",
    },
    {
      value: 8,
      day: "2017-10-22",
    },
    {
      value: 120,
      day: "2015-05-17",
    },
    {
      value: 170,
      day: "2017-04-23",
    },
    {
      value: 301,
      day: "2016-08-20",
    },
    {
      value: 390,
      day: "2018-05-13",
    },
    {
      value: 120,
      day: "2018-03-29",
    },
    {
      value: 144,
      day: "2017-02-22",
    },
    {
      value: 334,
      day: "2016-01-21",
    },
    {
      value: 133,
      day: "2015-11-22",
    },
    {
      value: 359,
      day: "2016-08-26",
    },
    {
      value: 257,
      day: "2018-01-06",
    },
    {
      value: 389,
      day: "2016-07-08",
    },
    {
      value: 217,
      day: "2017-01-02",
    },
    {
      value: 362,
      day: "2017-06-04",
    },
    {
      value: 313,
      day: "2018-07-09",
    },
    {
      value: 115,
      day: "2016-03-01",
    },
    {
      value: 339,
      day: "2016-08-09",
    },
    {
      value: 223,
      day: "2018-07-14",
    },
    {
      value: 9,
      day: "2016-09-10",
    },
    {
      value: 62,
      day: "2017-01-03",
    },
    {
      value: 194,
      day: "2016-07-25",
    },
    {
      value: 241,
      day: "2015-11-11",
    },
    {
      value: 232,
      day: "2017-01-31",
    },
    {
      value: 118,
      day: "2016-05-03",
    },
    {
      value: 309,
      day: "2018-07-25",
    },
    {
      value: 280,
      day: "2015-06-23",
    },
    {
      value: 374,
      day: "2016-01-15",
    },
    {
      value: 154,
      day: "2017-10-13",
    },
    {
      value: 8,
      day: "2015-05-31",
    },
    {
      value: 337,
      day: "2016-05-12",
    },
    {
      value: 110,
      day: "2016-10-04",
    },
    {
      value: 237,
      day: "2015-07-19",
    },
    {
      value: 8,
      day: "2016-05-27",
    },
    {
      value: 348,
      day: "2016-05-17",
    },
    {
      value: 164,
      day: "2017-03-14",
    },
    {
      value: 214,
      day: "2018-01-20",
    },
    {
      value: 277,
      day: "2017-12-15",
    },
    {
      value: 332,
      day: "2017-12-01",
    },
    {
      value: 251,
      day: "2016-02-04",
    },
    {
      value: 335,
      day: "2017-07-02",
    },
    {
      value: 63,
      day: "2017-03-12",
    },
    {
      value: 188,
      day: "2015-07-15",
    },
    {
      value: 246,
      day: "2018-07-30",
    },
    {
      value: 268,
      day: "2018-05-01",
    },
    {
      value: 93,
      day: "2018-04-08",
    },
    {
      value: 102,
      day: "2016-01-06",
    },
    {
      value: 312,
      day: "2016-04-29",
    },
    {
      value: 30,
      day: "2015-11-08",
    },
    {
      value: 397,
      day: "2018-04-04",
    },
    {
      value: 308,
      day: "2018-03-14",
    },
    {
      value: 8,
      day: "2016-06-12",
    },
    {
      value: 327,
      day: "2017-07-21",
    },
    {
      value: 275,
      day: "2015-07-22",
    },
    {
      value: 6,
      day: "2016-07-01",
    },
    {
      value: 184,
      day: "2015-12-17",
    },
    {
      value: 147,
      day: "2017-07-20",
    },
    {
      value: 239,
      day: "2017-08-10",
    },
    {
      value: 153,
      day: "2016-08-07",
    },
    {
      value: 216,
      day: "2015-11-01",
    },
    {
      value: 219,
      day: "2015-08-04",
    },
    {
      value: 22,
      day: "2015-05-22",
    },
    {
      value: 145,
      day: "2018-05-02",
    },
    {
      value: 277,
      day: "2016-12-04",
    },
    {
      value: 112,
      day: "2018-07-28",
    },
    {
      value: 390,
      day: "2018-06-25",
    },
    {
      value: 35,
      day: "2016-10-22",
    },
    {
      value: 233,
      day: "2017-08-20",
    },
    {
      value: 335,
      day: "2017-05-01",
    },
    {
      value: 159,
      day: "2016-05-01",
    },
    {
      value: 357,
      day: "2016-06-09",
    },
    {
      value: 221,
      day: "2016-10-30",
    },
    {
      value: 185,
      day: "2016-11-29",
    },
    {
      value: 254,
      day: "2016-02-24",
    },
    {
      value: 232,
      day: "2016-11-28",
    },
    {
      value: 359,
      day: "2018-02-06",
    },
    {
      value: 191,
      day: "2015-11-18",
    },
    {
      value: 255,
      day: "2018-04-30",
    },
    {
      value: 197,
      day: "2015-10-20",
    },
    {
      value: 149,
      day: "2018-03-28",
    },
    {
      value: 74,
      day: "2017-07-03",
    },
    {
      value: 335,
      day: "2017-06-26",
    },
    {
      value: 123,
      day: "2015-06-07",
    },
    {
      value: 284,
      day: "2016-09-26",
    },
    {
      value: 47,
      day: "2018-06-28",
    },
    {
      value: 191,
      day: "2017-07-28",
    },
    {
      value: 233,
      day: "2016-08-23",
    },
    {
      value: 172,
      day: "2016-09-25",
    },
    {
      value: 88,
      day: "2017-08-02",
    },
    {
      value: 309,
      day: "2017-11-03",
    },
    {
      value: 279,
      day: "2015-09-03",
    },
    {
      value: 161,
      day: "2017-02-11",
    },
    {
      value: 202,
      day: "2018-04-24",
    },
    {
      value: 273,
      day: "2017-10-11",
    },
    {
      value: 375,
      day: "2017-01-15",
    },
    {
      value: 385,
      day: "2017-03-29",
    },
    {
      value: 308,
      day: "2016-02-19",
    },
    {
      value: 350,
      day: "2017-01-18",
    },
    {
      value: 242,
      day: "2017-07-15",
    },
    {
      value: 387,
      day: "2018-07-29",
    },
    {
      value: 359,
      day: "2016-11-22",
    },
    {
      value: 198,
      day: "2017-11-08",
    },
    {
      value: 84,
      day: "2016-11-06",
    },
    {
      value: 310,
      day: "2018-05-05",
    },
    {
      value: 228,
      day: "2016-04-06",
    },
    {
      value: 235,
      day: "2018-02-16",
    },
    {
      value: 26,
      day: "2015-07-30",
    },
    {
      value: 35,
      day: "2016-11-19",
    },
  ];
  return (
    <>
      {/* <Button variant="outline" onClick={() => setOpened((o) => !o)}>
        Show
      </Button>
      <Collapse in={opened}> */}
      <div>
        {db_Data?.length !== 0 && db_Data?.length !== undefined ? (
          <div className="Chart_Calender1">
            <div
              style={{
                height: "100px",
                width: "500px",
                backgroundColor: "#b3dcbf",
                color: "black",
              }}
            >
              <ResponsiveCalendar
                data={db_Data}
                from={db_Data[0].day}
                to={db_Data[db_Data.length - 1].day}
                minValue={0}
                maxValue={24}
                emptyColor="#eeeeee"
                colors={[
                  "#F2F5A9",
                  "#BFFF00",
                  "#2EFE2E",
                  "#00BFFF",
                  "#0080FF",
                  "#9A2EFE",
                  "#DF0101",
                  "#8A0808",
                  "#8A0808",
                  "#8A0808",
                  "#8A0808",
                  "#8A0808",
                ]}
                margin={{ top: 20, right: 20, bottom: 5, left: 20 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "row",
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: "right-to-left",
                  },
                ]}
              />
            </div>
            {/* <div
                style={{
                  height: "100px",
                  width: "500px",
                  backgroundColor: "gray",
                  color: "black",
                }}
              >
                <ResponsiveCalendar
                  data={db_Data[1]}
                  from={db_Data[1][0].day}
                  to={db_Data[1][db_Data[1].length - 1].day}
                  minValue={0}
                  maxValue={24}
                  emptyColor="#eeeeee"
                  colors={[
                    "#F2F5A9",
                    "#BFFF00",
                    "#2EFE2E",
                    "#00BFFF",
                    "#0080FF",
                    "#9A2EFE",
                    "#DF0101",
                    "#8A0808",
                    "#8A0808",
                    "#8A0808",
                    "#8A0808",
                    "#8A0808",
                  ]}
                  margin={{ top: 20, right: 20, bottom: 5, left: 20 }}
                  yearSpacing={40}
                  monthBorderColor="#ffffff"
                  dayBorderWidth={2}
                  dayBorderColor="#ffffff"
                  legends={[
                    {
                      anchor: "bottom-right",
                      direction: "row",
                      translateY: 36,
                      itemCount: 4,
                      itemWidth: 42,
                      itemHeight: 36,
                      itemsSpacing: 14,
                      itemDirection: "right-to-left",
                    },
                  ]}
                />
              </div>
              <div
                style={{
                  height: "100px",
                  width: "500px",
                  backgroundColor: "gray",
                  color: "black",
                }}
              >
                <ResponsiveCalendar
                  data={db_Data[2]}
                  from={db_Data[2][0].day}
                  to={db_Data[2][db_Data[2].length - 1].day}
                  minValue={0}
                  maxValue={24}
                  emptyColor="#eeeeee"
                  colors={[
                    "#F2F5A9",
                    "#BFFF00",
                    "#2EFE2E",
                    "#00BFFF",
                    "#0080FF",
                    "#9A2EFE",
                    "#DF0101",
                    "#8A0808",
                    "#8A0808",
                    "#8A0808",
                    "#8A0808",
                    "#8A0808",
                  ]}
                  margin={{ top: 20, right: 20, bottom: 5, left: 20 }}
                  yearSpacing={40}
                  monthBorderColor="#ffffff"
                  dayBorderWidth={2}
                  dayBorderColor="#ffffff"
                  legends={[
                    {
                      anchor: "bottom-right",
                      direction: "row",
                      translateY: 36,
                      itemCount: 4,
                      itemWidth: 42,
                      itemHeight: 36,
                      itemsSpacing: 14,
                      itemDirection: "right-to-left",
                    },
                  ]}
                />
              </div> */}
          </div>
        ) : null}
      </div>
      {/* </Collapse> */}
    </>
  );
};

export default Chart_Calendar;
