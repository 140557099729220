import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
} from "react";
import { UserContext } from "../UserContext";
import axios from "axios";

import useFirebase from "../Hooks/useFirebase";
import useDBAuth from "../Hooks/useDBAuth";

// ------------------------------------------------- firebase 1/3
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";

// ------------------------------------------------- Pages
import Comp_UserProfile from "../Component/Comp_UserProfile";
import Page_CafePC_PCStatus from "../Page/Page_CafePC_PCStatus";
import Page_CafePC_PCStatus_v2 from "../Page/Page_CafePC_PCStatus_v2";
import Page_CafePC_PCSHardware_v2 from "../Page/Page_CafePC_PCSHardware_v2";

// ------------------------------------------------- MUI Table 1/3
// import MaterialTable from "material-table";

// ------------------------------------------------- MUI Dialog 1/
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// -------------------------------------------------------------------------------------MUI - Tab  1/4
import PropTypes from "prop-types";

import { Typography, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import Page_Login from "./Page_Login";
import Page_CafePC_GameDP from "./Page_CafePC_GameDP";

// -------------------------------------------------  AutoComplete 1/3
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
// import Autocomplete, {
//   createFilterOptions,
// } from "@material-ui/lab/Autocomplete";
// import { MoreVert } from "@material-ui/icons";
// import { Alert } from "@material-ui/lab";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

// test
const handleSubmit = (e, notes, set_notes, input, set_input) => {
  e.preventDefault();
  const id = notes.length ? notes[notes.length - 1].id + 1 : 0;
  set_notes([...notes, { id: id, message: input }]);
  set_input("");
};

const delNotes = (id, notes, set_notes) => {
  set_notes(notes.filter((note) => note.id != id));
};

export default function X(props) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);
  // --------------------------------------- auth User
  const [firebase_Status, firebase_SignOut, firebase_LoginUI] = useFirebase();
  const [dbAuth_Status, dbAuth_Login_Obj] = useDBAuth();

  const renderLimit = useRef(2);
  if (firebase_Status?.login_Status === true && renderLimit.current > 0) {
    // console.log("P1");
    dbAuth_Login_Obj.u_email = firebase_Status?.userInfo_Email;
    renderLimit.current--;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // -------------------------------------------------  Context Child 2/3
  const [u_department, setU_department] = useState("N.A");
  const [u_role, setU_role] = useState("N.A");

  // -------------------------------------------------  Variable
  const [patronName, setPatronName] = useState();
  const [patronContact, setPatronContact] = useState();
  const [patronEmail, setPatronEmail] = useState("sample@gmail.com");
  const [patronNote, setPatronNote] = useState();

  const [productQuantity, set_productQuantity] = useState(50);

  // -------------------------------------------------------------------------------------MUI - Tab  3/4
  const handleChange = (event, newValue) => {
    setTabIndexValue(newValue);
  };

  // const useStyles = makeStyles({
  //   tab2_GBColor: {
  //     background: "linear-gradient(45deg, #344955   30%, #50727B 90%)",
  //     borderRadius: 3,
  //     border: 1,
  //     color: "#B3DCBF",
  //     height: 48,
  //     padding: "0 0px",
  //     // boxShadow: "0 3px 30px 2px rgba(255, 231, 15, .3)",
  //   },
  // });
  // const classes = useStyles();
  const [tabIndexValue, setTabIndexValue] = useState(0);

  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 200,
        width: "100%",
        background: "linear-gradient(45deg, #E7F4EB  30%,#C6E7D0 90%)",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  // -------------------------------------------------------------------------------------MUI - Tab  2/4
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
          window.location.assign(props.href);
        }}
        {...props}
      />
    );
  }

  //  Get Date from DB -----------------------------------------------------------------------------------------

  const [dbData, setDbData] = useState();
  const [dbData_Length, setDbData_Length] = useState();
  const getData = () => {
    const response1 = axios
      .get(`${REACT_APP_ECLIPSE_API}/get_careu_hardwareinfo`)
      .then((date) => {
        setDbData(date.data);
        setDbData_Length(date.data.length);
        console.log(date.data);
      });
  };
  // #
  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    // getData();
  }, []);

  //-------------------------------------------------------------------------- main return()
  return (
    <>
      {firebase_Status?.login_Status === false ? (
        <Page_Login />
      ) : dbAuth_Status?.Auth_Login === true ? (
        <>
          <Comp_UserProfile />

          <AppBar position="sticky">
            <div>
              <div
                // className={classes.tab2_GBColor}
                className="nav2_theme"
              >
                <StyledTabs
                  value={tabIndexValue}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={<div>Cafe Status (V2)</div>} {...a11yProps(0)} />
                  <Tab label={<div>PC Hardware (V2)</div>} {...a11yProps(1)} />
                  <Tab label={<div>Games DP </div>} {...a11yProps(2)} />
                </StyledTabs>
              </div>
            </div>
          </AppBar>
          <TabPanel value={tabIndexValue} index={0}>
            <Page_CafePC_PCStatus_v2 />
          </TabPanel>
          <TabPanel value={tabIndexValue} index={1}>
            <Page_CafePC_PCSHardware_v2 />
          </TabPanel>
          <TabPanel value={tabIndexValue} index={2}>
            <Page_CafePC_GameDP />
          </TabPanel>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
}
