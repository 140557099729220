import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

// ------------------------------------------------- REACT TABLE
// import { CompactTable } from "@table-library/react-table-library/compact";
// import { useTheme } from "@table-library/react-table-library/theme";
// import { getTheme } from "@table-library/react-table-library/baseline";
// ------------------------------------------------- MUI Table 1/3
import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";

// ------------------------------------------------- Maintine

import {
  Button as ButtonMT,
  TextInput,
  Collapse,
  Checkbox,
} from "@mantine/core";

import Chart_Bar from "../Chart/Chart_Bar";
import Comp_CafePC_PCHardware_Log_v2 from "../Component/Comp_CafePC_PCHardware_Log_v2";

const { REACT_APP_ECLIPSE_API, REACT_APP_ECLIPSE_API_LOCAL } = process.env;

export default function X() {
  //  Get Data from DB -----------------------------------------------------------------------------------------

  const [db_CafeHardwareInfo, setBD_CafeHardwareInfo] = useState([]);
  const get_DB_CafeHardwardInfo = async (cafe, searchkey) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_status_log__hardwareinfo`,
        {
          cafe,
          searchkey,
        }
      )
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length > 5) {
          res.data[5].push({ cafe: "all", count: 0 });
        }

        setBD_CafeHardwareInfo(res.data);
        console.log("get_DB_CafeHardwardInfo", res.data);
      });
  };

  //  -------------------------- Table column
  // const nodes = [
  //   {
  //     id: "0",
  //     name: "Shopping List",
  //     // deadline: new Date(2020, 1, 15),
  //     // type: "TASK",
  //     // isComplete: true,
  //     // nodes: 3,
  //   },
  // ];

  // const theme = useTheme({
  //   Row: `
  //       cursor: pointer;

  //       .td {
  //         border-top: 1px solid #a0a8ae;
  //         border-bottom: 1px solid #a0a8ae;
  //       }

  //       &:hover .td {
  //         border-top: 1px solid orange;
  //         border-bottom: 1px solid orange;
  //       }
  //     `,
  // });

  // const COLUMNS = [
  //   { label: "id", renderCell: (item) => item.id },
  //   // { label: "Task", renderCell: (item) => item.name },
  //   // {
  //   //   label: "Deadline",
  //   //   renderCell: (item) =>
  //   //     item.deadline.toLocaleDateString("en-US", {
  //   //       year: "numeric",
  //   //       month: "2-digit",
  //   //       day: "2-digit",
  //   //     }),
  //   // },
  //   // { label: "Type", renderCell: (item) => item.type },
  //   {
  //     label: "Complete",
  //     renderCell: (item) => item?.name.toString(),
  //   },
  //   // { label: "Tasks", renderCell: (item) => item.nodes },
  // ];

  // const data = { nodes };

  //-------------------------------------------------------------------------- global var

  const [selected_Mon, setSelected_Mon] = useState();
  const [selected_CPU, setSelected_CPU] = useState();
  const [selected_GPU, setSelected_GPU] = useState();
  const [selected_RAM, setSelected_RAM] = useState();
  const [selected_MOBO, setSelected_MOBO] = useState();
  const [selected_Cafe, setSelected_Cafe] = useState("all");

  const [searchKey, setSelected_SearchKey] = useState("");

  const [checked_ShowAllCafeList, setChecked_ShowAllCafeList] = useState(false);
  const [checked_ShowAllPcList, setChecked_ShowAllPcList] = useState(false);

  // ---------------------------------------------------------  Dialog

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    get_DB_CafeHardwardInfo("all", "");
  }, []);

  //-------------------------------------------------------------------------- Component

  //-------------------------------------------------------------------------- func

  //-------------------------------------------------------------------------- main return()

  return (
    <>
      <div className="PCHardware_Container">
        <div className="PCHardware_CP_Container">
          <h1>PC HARDWARE V2</h1>
          {/* <CompactTable columns={COLUMNS} data={data} theme={theme} /> */}
          <TextInput
            value={searchKey}
            onChange={(event) =>
              setSelected_SearchKey(event.currentTarget.value)
            }
          />
          <ButtonMT
            onClick={() => {
              get_DB_CafeHardwardInfo(selected_Cafe, searchKey);
            }}
          >
            {`Search (within ${selected_Cafe})`}
          </ButtonMT>

          <br />
          <Checkbox
            size="xl"
            checked={checked_ShowAllCafeList}
            onChange={(event) =>
              setChecked_ShowAllCafeList(event.currentTarget.checked)
            }
            label={
              <div style={{ color: "lime" }}>
                {`All Cafe List ${
                  checked_ShowAllCafeList ? "SHOWN" : "HIDDEN"
                }`}
              </div>
            }
            color="lime"
          />
          <Checkbox
            size="xl"
            disabled={selected_Cafe === "all"}
            checked={checked_ShowAllPcList}
            onChange={(event) => {
              setChecked_ShowAllPcList(event.currentTarget.checked);
              if (checked_ShowAllCafeList === false) {
                setChecked_ShowAllCafeList(event.currentTarget.checked);
              }
            }}
            label={
              <div
                style={{ color: selected_Cafe === "all" ? "" : "orange" }}
              >{`All Pc List ${
                checked_ShowAllPcList ? "SHOWN" : "HIDDEN"
              }`}</div>
            }
            color="orange"
          />
        </div>
        <br />

        <hr />
        <br />
        <div className="">
          {/* CAFE */}
          <div>
            <div className="">{`Cafe (${db_CafeHardwareInfo?.[5]?.reduce(
              (acc, cur) => {
                return acc + cur.count;
              },
              0
            )}pc)`}</div>
            <div className="PCHardware_Container_Cafe">
              {db_CafeHardwareInfo?.[5]?.map((x, i) => {
                return (
                  <div
                    className="PCHardware_Container_Cafe_sub "
                    style={{
                      backgroundColor:
                        selected_Cafe === x?.cafe ? "#662900" : "",
                    }}
                    onClick={() => {
                      setSelected_Cafe(x?.cafe);
                      get_DB_CafeHardwardInfo(x?.cafe, searchKey);
                      setChecked_ShowAllCafeList(false);
                      setChecked_ShowAllPcList(false);
                      console.log("x?.cafe", x?.cafe);
                    }}
                  >
                    <div>{`${i + 1}`}</div>
                    <div>{`${x?.cafe}`}</div>

                    <div>{x?.count}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <hr />
        <br />
        <br />
        <div className="PCHardware_Container_2">
          {/* MON */}
          <div>
            <div className="PCHardware_Container_2_title">{`Monitor (${db_CafeHardwareInfo?.[0]?.reduce(
              (acc, cur) => {
                return acc + cur.count;
              },
              0
            )}pc)`}</div>
            <div className="">
              {db_CafeHardwareInfo?.[0]?.map((x, i) => {
                return (
                  <div className="PCHardware_Container_3alpha">
                    <div
                      className={`PCHardware_Container_3`}
                      style={{
                        backgroundColor:
                          selected_Mon === x?.MON_Manufacturer ? "#662900" : "",
                      }}
                      onClick={() => {
                        setSelected_Mon(x?.MON_Manufacturer);
                        console.log("x?.MON_Manufacturer", x?.MON_Manufacturer);
                      }}
                    >
                      <div>{`${i + 1}`}</div>
                      <div>{x?.MON_Manufacturer}</div>
                      <div>{x?.count}</div>
                    </div>
                    <div>
                      <Get_Cafe_PCCount
                        hw_name="MON_Manufacturer"
                        hw_value={x?.MON_Manufacturer}
                        cafe={selected_Cafe}
                        checked_ShowAllCafeList={checked_ShowAllCafeList}
                        checked_ShowAllPcList={checked_ShowAllPcList}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* CPU */}
          <div>
            <div className="PCHardware_Container_2_title">{`CPU (${db_CafeHardwareInfo?.[1]?.reduce(
              (acc, cur) => {
                return acc + cur.count;
              },
              0
            )}pc)`}</div>
            <div className="">
              {db_CafeHardwareInfo?.[1]?.map((x, i) => {
                return (
                  <div className="PCHardware_Container_3alpha">
                    <div
                      className="PCHardware_Container_3"
                      style={{
                        backgroundColor:
                          selected_CPU === x?.CPU_Name ? "#662900" : "",
                      }}
                      onClick={() => {
                        setSelected_CPU(x?.CPU_Name);
                        console.log("x?.CPU_Name", x?.CPU_Name);
                      }}
                    >
                      <div>{`${i + 1}`}</div>
                      <div>{x?.CPU_Name}</div>
                      <div>{x?.count}</div>
                    </div>
                    <div>
                      <Get_Cafe_PCCount
                        hw_name="CPU_Name"
                        hw_value={x?.CPU_Name}
                        cafe={selected_Cafe}
                        checked_ShowAllCafeList={checked_ShowAllCafeList}
                        checked_ShowAllPcList={checked_ShowAllPcList}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* GPU */}
          <div>
            <div className="PCHardware_Container_2_title">{`GPU (${db_CafeHardwareInfo?.[2]?.reduce(
              (acc, cur) => {
                return acc + cur.count;
              },
              0
            )}pc)`}</div>
            <div className="">
              {db_CafeHardwareInfo?.[2]?.map((x, i) => {
                return (
                  <div className="PCHardware_Container_3alpha">
                    <div
                      className="PCHardware_Container_3"
                      style={{
                        backgroundColor:
                          selected_GPU === x?.GPU_Name ? "#662900" : "",
                      }}
                      onClick={() => {
                        setSelected_GPU(x?.GPU_Name);
                        console.log("x?.GPU_Name", x?.GPU_Name);
                      }}
                    >
                      <div>{`${i + 1}`}</div>
                      <div>{`${x?.GPU_Name} [${x?.GPU_AdapterRAM}]`}</div>

                      <div>{x?.count}</div>
                    </div>
                    <div>
                      <Get_Cafe_PCCount
                        hw_name="GPU_Name"
                        hw_value={x?.GPU_Name}
                        cafe={selected_Cafe}
                        checked_ShowAllCafeList={checked_ShowAllCafeList}
                        checked_ShowAllPcList={checked_ShowAllPcList}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* RAM */}
          <div>
            <div className="PCHardware_Container_2_title">{`RAM (${db_CafeHardwareInfo?.[3]?.reduce(
              (acc, cur) => {
                return acc + cur.count;
              },
              0
            )}pc)`}</div>
            <div className="">
              {db_CafeHardwareInfo?.[3]?.map((x, i) => {
                return (
                  <div className="PCHardware_Container_3alpha">
                    <div
                      className="PCHardware_Container_3"
                      style={{
                        backgroundColor:
                          selected_RAM === x?.RAM_Capacity ? "#662900" : "",
                      }}
                      onClick={() => {
                        setSelected_RAM(x?.RAM_Capacity);
                        console.log("x?.RAM_Capacity", x?.RAM_Capacity);
                      }}
                    >
                      <div>{`${i + 1}`}</div>
                      <div>{`${x?.RAM_Capacity} [${x?.RAM_Capacity}]`}</div>

                      <div>{x?.count}</div>
                    </div>
                    <div>
                      <Get_Cafe_PCCount
                        hw_name="RAM_Capacity"
                        hw_value={x?.RAM_Capacity}
                        cafe={selected_Cafe}
                        checked_ShowAllCafeList={checked_ShowAllCafeList}
                        checked_ShowAllPcList={checked_ShowAllPcList}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* MOBO */}
          <div>
            <div className="PCHardware_Container_2_title">{`MOBO (${db_CafeHardwareInfo?.[4]?.reduce(
              (acc, cur) => {
                return acc + cur.count;
              },
              0
            )}pc)`}</div>
            <div className="">
              {db_CafeHardwareInfo?.[4]?.map((x, i) => {
                return (
                  <div className="PCHardware_Container_3alpha">
                    <div
                      className="PCHardware_Container_3"
                      style={{
                        backgroundColor:
                          selected_MOBO === x?.MOBO_Product ? "#662900" : "",
                      }}
                      onClick={() => {
                        setSelected_MOBO(x?.MOBO_Product);
                        console.log("x?.MOBO_Product", x?.MOBO_Product);
                      }}
                    >
                      <div>{`${i + 1}`}</div>
                      <div>{`${x?.MOBO_Product}`}</div>

                      <div>{x?.count}</div>
                    </div>
                    <div>
                      <Get_Cafe_PCCount
                        hw_name="MOBO_Product"
                        hw_value={x?.MOBO_Product}
                        cafe={selected_Cafe}
                        checked_ShowAllCafeList={checked_ShowAllCafeList}
                        checked_ShowAllPcList={checked_ShowAllPcList}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//------------------------------------------------ Component
const Get_Cafe_PCCount = ({
  hw_name,
  hw_value,
  cafe,
  checked_ShowAllCafeList,
  checked_ShowAllPcList,
}) => {
  const [data, setData] = useState([]);
  const get_DB_CafePC_ByHwInfo = async (hw_name, hw_value) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__hardware_locatedcafe_pccount__byhardwarename`,
        {
          hw_name,
          hw_value,
          cafe,
        }
      )
      .then((res) => {
        console.log("get_DB_CafePC_ByHwInfo");
        setData(res.data);
      });
  };

  const [db_CafeHardware, setBD_CafeHardware] = useState([]);
  const get_DB_CafeHardware_byCafe = async (
    hw_name,
    hw_value,
    cafe,
    pc_name
  ) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_live__byhwcafe`,
        {
          hw_name,
          hw_value,
          cafe,
          pc_name,
        }
      )
      .then((res) => {
        setBD_CafeHardware(res.data[2]);
        console.log(
          "get_eclipse_careu__cafe_pc_list_live__byhwcafe",
          res.data[2]
        );
        handleClickOpen_1_1();
      });
  };

  const [opened, setOpened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  //  -------------------------- Dialog

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    //    Dialog Close Button Style
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "red",
    },
  });

  const [open_1_1, setOpen_1_1] = React.useState(false);

  const handleClickOpen_1_1 = () => {
    setOpen_1_1(true);
  };
  const handleClose_1_1 = () => {
    setOpen_1_1(false);
  };
  //   Dialog Close Button
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  // ---------------------------------------------------------  Table
  // const TableRows = [].map((x) => (
  //   <tr key={x?.name}>
  //     <td>{x?.position}</td>
  //     <td>{x?.name}</td>
  //     <td>{x?.symbol}</td>
  //     <td>{x?.mass}</td>
  //   </tr>
  // ));

  const [state, setState] = React.useState({
    columns: [
      {
        title: "# NO",
        field: "num",
        minWidth: "1px",
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "5%",
        render: (rowData) => (
          <>
            <div
              style={{
                backgroundColor: rowData?.NIC_Speed.toString().includes("100M")
                  ? "darkred"
                  : "",
              }}
            >{`${rowData?.NIC_Speed}`}</div>
          </>
        ),
      },
      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "5%",
        render: (rowData) => (
          <div
            style={{
              backgroundColor:
                rowData?.CPU_VirtualizationFirmwareEnabled === "False"
                  ? "darkred"
                  : "",
            }}
          >
            {rowData?.CPU_VirtualizationFirmwareEnabled}
          </div>
        ),
      },
      {
        title: "offlined",
        field: "OFFLINE_Hour",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <div
            style={{
              backgroundColor: rowData?.OFFLINE_Hour > 24 ? "darkred" : "",
            }}
          >
            <div>{`${(rowData?.OFFLINE_Hour / 24).toFixed(1)} Days`}</div>
            <div>{`(${rowData?.OFFLINE_Hour} Hours)`}</div>
          </div>
        ),
      },

      { title: "cafe", field: "cafe", width: "5%" },
      { title: "PC_Name", field: "PC_Name", width: "5%" },
      {
        title: "MAC IP",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.NIC_MAC}</div>
            <div> {rowData?.NIC_IP}</div>
          </>
        ),
      },
      { title: "PC_StartUpTime", field: "PC_StartUpTime", width: "5%" },
      {
        title: "IP & MAC",
        field: "IP & MAC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.PC_OS_Ver}</div>
            <div> {rowData?.PC_OS}</div>
          </>
        ),
      },

      { title: "MON", field: "MON_Manufacturer", width: "5%" },
      { title: "FPS", field: "mon_fps", width: "5%" },
      { title: "CPU", field: "CPU_Name", width: "5%" },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "5%",
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "5%",
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "5%",
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "5%",
      },
      {
        title: "RAM",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.RAM_Manufacturer}</div>
            <div> {rowData?.RAM_PartNumber}</div>
          </>
        ),
      },

      {
        title: "RAM",
        field: "RAM_Capacity",
        width: "5%",
      },
      {
        title: "NIC 1",
        field: "NIC_InstanceName",
        width: "5%",
      },

      {
        title: "created",
        field: "create_dt",
        width: "5%",
      },
      {
        title: "updated",
        field: "update_dt",
        width: "5%",
      },
    ],
    columns_sensor: [
      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "5%",
      },
      {
        title: "offlined",
        field: "OFFLINE_Hour",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div>{`${(rowData?.OFFLINE_Hour / 24).toFixed(1)} Days`}</div>
            <div>{`(${rowData?.OFFLINE_Hour} Hours)`}</div>
          </>
        ),
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "5%",
      },
      { title: "cafe", field: "cafe", width: "5%" },
      { title: "PC_Name", field: "PC_Name", width: "5%" },

      {
        title: "Sensors (15min/sample) def:1d",
        field: "",
        // width: "1%",
        headerStyle: {
          minWidth: "300px",
        },
        render: (rowData) => (
          <>
            <Chart_Bar
              p_data={{
                cafe: rowData?.cafe,
                PC_Name: rowData?.PC_Name,
                // Chart_Title: "cpu_usage",
                // Chart_Colors: "yellow_orange_red",
                sample_amount: 4 * 24,
              }}
            />
          </>
        ),
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.NIC_MAC}</div>
            <div> {rowData?.NIC_IP}</div>
          </>
        ),
      },
      { title: "PC_StartUpTime", field: "PC_StartUpTime", width: "5%" },
      {
        title: "IP & MAC",
        field: "IP & MAC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.PC_OS_Ver}</div>
            <div> {rowData?.PC_OS}</div>
          </>
        ),
      },

      { title: "MON", field: "MON_Manufacturer", width: "5%" },
      { title: "FPS", field: "mon_fps", width: "5%" },
      { title: "CPU", field: "CPU_Name", width: "5%" },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "5%",
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "5%",
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "5%",
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "5%",
      },
      {
        title: "RAM",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.RAM_Manufacturer}</div>
            <div> {rowData?.RAM_PartNumber}</div>
          </>
        ),
      },

      {
        title: "RAM",
        field: "RAM_Capacity",
        width: "5%",
      },
      {
        title: "NIC 1",
        field: "NIC_InstanceName",
        width: "5%",
      },

      {
        title: "created",
        field: "create_dt",
        width: "5%",
      },
      {
        title: "updated",
        field: "update_dt",
        width: "5%",
      },
    ],
    columns2: [
      // MOBO_Fan_Speed, MOBO_Temperature, CPU_Usage, CPU_Temperature, RAM_Usage, GPU_Usage, GPU_Temperature, GPU_Fan_Speed, GPU_VRAM_Usage, create_dt
      {
        title: "create_dt",
        field: "create_dt",
        // width: "5%",
      },
      {
        title: "MOBO_Fan_Speed",
        field: "MOBO_Fan_Speed",
        // width: "5%",
      },
      {
        title: "MOBO_Temperature",
        field: "MOBO_Temperature",
        // width: "5%",
      },
      {
        title: "CPU_Usage",
        field: "CPU_Usage",
        // width: "5%",
      },
      {
        title: "CPU_Temperature",
        field: "CPU_Temperature",
        // width: "5%",
      },
      {
        title: "RAM_Usage",
        field: "RAM_Usage",
        // width: "5%",
      },
      {
        title: "GPU_Temperature",
        field: "GPU_Temperature",
        // width: "5%",
      },
      {
        title: "GPU_Usage",
        field: "GPU_Usage",
        // width: "5%",
      },
      {
        title: "GPU_Fan_Speed",
        field: "GPU_Fan_Speed",
        // width: "5%",
      },
      {
        title: "GPU_VRAM_Usage",
        field: "GPU_VRAM_Usage",
        // width: "5%",
      },
    ],
  });
  // ---------------------------------------------------------  Global Variable
  const [showSensor, setShowSensor] = useState(false);

  useEffect(() => {
    setData([]);
    console.log("checked_ShowAllCafeList", checked_ShowAllCafeList);
    setOpened(checked_ShowAllCafeList);
  }, [checked_ShowAllCafeList]);

  useEffect(() => {
    setData([]);
    if (checked_ShowAllCafeList === true || opened === true) {
      get_DB_CafePC_ByHwInfo(hw_name, hw_value);
    }
  }, [hw_name, hw_value, opened]);

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            color: "darkorange",
            background: "rgba(255, 255, 255, .1)",
            boxShadow: "10px 8px 100px 40px rgba( 0, 0, 0, 1 )",
            backdropFilter: "blur( 44.0px )",
          },
        }}
        fullScreen={true}
        // fullWidth="xl"
        maxWidth={true}
        scroll="body"
        aria-labelledby="max-width-dialog-title"
        open={open_1_1}
        onClose={handleClose_1_1}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose_1_1}>
          <Typography
            variant="caption"
            display="block"
            style={{
              fontSize: "50px",
              // color: "red",
              padding: "20px 0 50px 0",
            }}
          >
            {/* EDIT */}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <div style={{ backgroundColor: "gray" }}>
            <MaterialTable
              title={
                <div className="FlexRow">
                  <h2 style={{ color: "#C5ECCD" }}>
                    <div>
                      {`Cafe PC Status : ${db_CafeHardware?.[0]?.cafe}`}{" "}
                    </div>
                  </h2>
                  <ButtonMT
                    variant="subtle"
                    onClick={() => setShowSensor(!showSensor)}
                  >
                    Show Sensors Charts : {showSensor ? "Show" : "Hidden"}
                  </ButtonMT>
                </div>
              }
              columns={
                showSensor === true ? state.columns_sensor : state.columns
              }
              data={db_CafeHardware}
              style={{
                color: "white",
                background: "rgba(36, 43, 38, .5)",
                // backgroundColor: "rgba(0,0,0,.1)",
                // backgroundColor:
                //   "linear-gradient(90deg,rgba(25, 30, 26,.3) .25%, rgba(0, 0, 0,.5) .25%)",
                // backdropFilter: "blur( 11.0px )",
                // "&::webkit-backdrop-filter": {
                //   webkitBackdropFilter: "blur( 18.0px )",
                // },
              }}
              options={{
                exportButton: true,

                headerStyle: {
                  background: "rgba(54, 64, 56,.5)",
                  border: "none",
                },
                rowStyle: (rowData) => ({
                  backgroundColor: "rgba(25, 30, 26,.1)",

                  color:
                    rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
                  // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
                  fontSize: 12,
                }),
                filtering: true,
                grouping: true,
                exportAllData: true,
                pageSize: 100,
                pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                paginationType: "stepped",
                // actionsColumnIndex: -1
                // fixedColumns: {
                //     left: 1,
                // }
              }}
              onRowClick={(evt, selectedRow) => {
                // console.log(selectedRow.id);
                // setSelected_Item(selectedRow);
                // handleClickOpen_1_1();
                // setSelected_Input_OrderId(selectedRow.order_id);
                // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
                // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
              }}
              detailPanel={[
                {
                  tooltip: "Show Hardware Changes Log",
                  render: (rowData) => {
                    // console.log("rowData", rowData);
                    return (
                      <div>
                        <Comp_CafePC_PCHardware_Log_v2
                          cafe={rowData?.cafe}
                          pc_name={rowData?.PC_Name}
                        />
                      </div>
                    );
                  },
                },
              ]}
              actions={
                [
                  // {
                  //   icon: "save",
                  //   tooltip: "Save User",
                  //   onClick: (event, rowData) => {
                  //     // get_DB_CafeHardware_StatusLog_byCafePCName(
                  //     //   rowData?.cafe,
                  //     //   rowData?.PC_Name
                  //     // );
                  //     setSelectedRow(rowData);
                  //     setDialog_Show(true);
                  //     console.log("rowData", rowData);
                  //   },
                  // },
                ]
              }
              components={
                {
                  // Action: (props) => (
                  //   <Button
                  //     disabled
                  //     onClick={(event) => props.action.onClick(event, props.data)}
                  //     color="inherit"
                  //     variant="text"
                  //     style={{ textTransform: "none" }}
                  //     size="small"
                  //   >
                  //     🔎???
                  //   </Button>
                  // ),
                }
              }
            />
          </div>
        </DialogContent>
      </Dialog>

      <div
        className="PCHardware_Container__cafelist"
        onClick={() => setOpened((o) => !o)}
      >
        {opened ? "hide" : "show more cafe ..."}
      </div>
      <Collapse in={opened}>
        {data?.map((x, i) => {
          return (
            <div className="PCHardware_Container_3sub_b4">
              <div
                className="PCHardware_Container_3sub"
                onClick={() => {
                  console.log("hw_name", hw_name);
                  console.log("hw_value", hw_value);
                  console.log("cafe", x?.cafe);
                  get_DB_CafeHardware_byCafe(hw_name, hw_value, x?.cafe, "");
                }}
              >
                <div>{i + 1} </div>
                <div>{x?.cafe} </div>
                <div>{x?.count} </div>
              </div>
              <div>
                <Get_CafePCName_Count
                  hw_name={hw_name}
                  hw_value={hw_value}
                  cafe={x?.cafe !== "all" ? x?.cafe : cafe}
                  // showed={cafe !== "all" ? true : false}
                  showed={checked_ShowAllPcList}
                />
              </div>
            </div>
          );
        })}
      </Collapse>
    </>
  );
};

const Get_CafePCName_Count = ({ hw_name, hw_value, cafe, showed }) => {
  const [data, setData] = useState([]);
  const get_DB_CafePC_ByHwInfo = async (hw_name, hw_value) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__hardware_locatedcafepcname__byhardwarename`,
        {
          hw_name,
          hw_value,
          cafe,
        }
      )
      .then((res) => {
        console.log("Get_CafePCName_Count");
        setData(res.data);
      });
  };

  const [db_CafeHardware, setBD_CafeHardware] = useState([]);
  const get_DB_CafeHardware_byCafe = async (
    hw_name,
    hw_value,
    cafe,
    pc_name
  ) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_live__byhwcafe`,
        {
          hw_name,
          hw_value,
          cafe,
          pc_name,
        }
      )
      .then((res) => {
        setBD_CafeHardware(res.data[2]);
        console.log(
          "get_eclipse_careu__cafe_pc_list_live__byhwcafe",
          res.data[2]
        );
        handleClickOpen_1_1();
      });
  };

  const [opened, setOpened] = useState(false);

  //  -------------------------- Dialog

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    //    Dialog Close Button Style
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "red",
    },
  });

  const [open_1_1, setOpen_1_1] = React.useState(false);

  const handleClickOpen_1_1 = () => {
    setOpen_1_1(true);
  };
  const handleClose_1_1 = () => {
    setOpen_1_1(false);
  };
  //   Dialog Close Button
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const [state, setState] = React.useState({
    columns: [
      {
        title: "# NO",
        field: "num",
        minWidth: "1px",
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "5%",
        render: (rowData) => (
          <>
            <div
              style={{
                backgroundColor: rowData?.NIC_Speed.toString().includes("100M")
                  ? "darkred"
                  : "",
              }}
            >{`${rowData?.NIC_Speed}`}</div>
          </>
        ),
      },
      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "5%",
        render: (rowData) => (
          <div
            style={{
              backgroundColor:
                rowData?.CPU_VirtualizationFirmwareEnabled === "False"
                  ? "darkred"
                  : "",
            }}
          >
            {rowData?.CPU_VirtualizationFirmwareEnabled}
          </div>
        ),
      },
      {
        title: "offlined",
        field: "OFFLINE_Hour",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <div
            style={{
              backgroundColor: rowData?.OFFLINE_Hour > 24 ? "darkred" : "",
            }}
          >
            <div>{`${(rowData?.OFFLINE_Hour / 24).toFixed(1)} Days`}</div>
            <div>{`(${rowData?.OFFLINE_Hour} Hours)`}</div>
          </div>
        ),
      },

      { title: "cafe", field: "cafe", width: "5%" },
      { title: "PC_Name", field: "PC_Name", width: "5%" },
      {
        title: "MAC IP",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.NIC_MAC}</div>
            <div> {rowData?.NIC_IP}</div>
          </>
        ),
      },
      { title: "PC_StartUpTime", field: "PC_StartUpTime", width: "5%" },
      {
        title: "IP & MAC",
        field: "IP & MAC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.PC_OS_Ver}</div>
            <div> {rowData?.PC_OS}</div>
          </>
        ),
      },

      { title: "MON", field: "MON_Manufacturer", width: "5%" },
      { title: "FPS", field: "mon_fps", width: "5%" },
      { title: "CPU", field: "CPU_Name", width: "5%" },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "5%",
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "5%",
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "5%",
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "5%",
      },
      {
        title: "RAM",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.RAM_Manufacturer}</div>
            <div> {rowData?.RAM_PartNumber}</div>
          </>
        ),
      },

      {
        title: "RAM",
        field: "RAM_Capacity",
        width: "5%",
      },
      {
        title: "NIC 1",
        field: "NIC_InstanceName",
        width: "5%",
      },

      {
        title: "created",
        field: "create_dt",
        width: "5%",
      },
      {
        title: "updated",
        field: "update_dt",
        width: "5%",
      },
    ],
    columns_sensor: [
      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "5%",
      },
      {
        title: "offlined",
        field: "OFFLINE_Hour",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div>{`${(rowData?.OFFLINE_Hour / 24).toFixed(1)} Days`}</div>
            <div>{`(${rowData?.OFFLINE_Hour} Hours)`}</div>
          </>
        ),
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "5%",
      },
      { title: "cafe", field: "cafe", width: "5%" },
      { title: "PC_Name", field: "PC_Name", width: "5%" },

      {
        title: "Sensors (15min/sample) def:1d",
        field: "",
        // width: "1%",
        headerStyle: {
          minWidth: "300px",
        },
        render: (rowData) => (
          <>
            <Chart_Bar
              p_data={{
                cafe: rowData?.cafe,
                PC_Name: rowData?.PC_Name,
                // Chart_Title: "cpu_usage",
                // Chart_Colors: "yellow_orange_red",
                sample_amount: 4 * 24,
              }}
            />
          </>
        ),
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.NIC_MAC}</div>
            <div> {rowData?.NIC_IP}</div>
          </>
        ),
      },
      { title: "PC_StartUpTime", field: "PC_StartUpTime", width: "5%" },
      {
        title: "IP & MAC",
        field: "IP & MAC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.PC_OS_Ver}</div>
            <div> {rowData?.PC_OS}</div>
          </>
        ),
      },

      { title: "MON", field: "MON_Manufacturer", width: "5%" },
      { title: "FPS", field: "mon_fps", width: "5%" },
      { title: "CPU", field: "CPU_Name", width: "5%" },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "5%",
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "5%",
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "5%",
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "5%",
      },
      {
        title: "RAM",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.RAM_Manufacturer}</div>
            <div> {rowData?.RAM_PartNumber}</div>
          </>
        ),
      },

      {
        title: "RAM",
        field: "RAM_Capacity",
        width: "5%",
      },
      {
        title: "NIC 1",
        field: "NIC_InstanceName",
        width: "5%",
      },

      {
        title: "created",
        field: "create_dt",
        width: "5%",
      },
      {
        title: "updated",
        field: "update_dt",
        width: "5%",
      },
    ],
    columns2: [
      // MOBO_Fan_Speed, MOBO_Temperature, CPU_Usage, CPU_Temperature, RAM_Usage, GPU_Usage, GPU_Temperature, GPU_Fan_Speed, GPU_VRAM_Usage, create_dt
      {
        title: "create_dt",
        field: "create_dt",
        // width: "5%",
      },
      {
        title: "MOBO_Fan_Speed",
        field: "MOBO_Fan_Speed",
        // width: "5%",
      },
      {
        title: "MOBO_Temperature",
        field: "MOBO_Temperature",
        // width: "5%",
      },
      {
        title: "CPU_Usage",
        field: "CPU_Usage",
        // width: "5%",
      },
      {
        title: "CPU_Temperature",
        field: "CPU_Temperature",
        // width: "5%",
      },
      {
        title: "RAM_Usage",
        field: "RAM_Usage",
        // width: "5%",
      },
      {
        title: "GPU_Temperature",
        field: "GPU_Temperature",
        // width: "5%",
      },
      {
        title: "GPU_Usage",
        field: "GPU_Usage",
        // width: "5%",
      },
      {
        title: "GPU_Fan_Speed",
        field: "GPU_Fan_Speed",
        // width: "5%",
      },
      {
        title: "GPU_VRAM_Usage",
        field: "GPU_VRAM_Usage",
        // width: "5%",
      },
    ],
  });

  const [showSensor, setShowSensor] = useState(false);

  useEffect(() => {
    setData([]);
    setOpened(showed);
  }, [showed]);

  useEffect(() => {
    setData([]);
    if (opened === true) {
      get_DB_CafePC_ByHwInfo(hw_name, hw_value);
    }
  }, [hw_name, hw_value, opened]);

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            color: "darkorange",
            background: "rgba(255, 255, 255, .1)",
            boxShadow: "10px 8px 100px 40px rgba( 0, 0, 0, 1 )",
            backdropFilter: "blur( 44.0px )",
          },
        }}
        fullScreen={true}
        // fullWidth="xl"
        maxWidth={true}
        scroll="body"
        aria-labelledby="max-width-dialog-title"
        open={open_1_1}
        onClose={handleClose_1_1}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose_1_1}>
          <Typography
            variant="caption"
            display="block"
            style={{
              fontSize: "20px",
              // color: "red",
              padding: "20px 0 50px 0",
            }}
          >
            {/* {`${hw_name} = ${hw_value}, ${cafe}`} */}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <div style={{ backgroundColor: "gray" }}>
            <MaterialTable
              title={
                <div className="FlexRow">
                  <h2 style={{ color: "#C5ECCD" }}>
                    <div>{`Cafe PC Status : ${cafe}`} </div>
                  </h2>
                  <ButtonMT
                    variant="subtle"
                    onClick={() => setShowSensor(!showSensor)}
                  >
                    Show Sensors Charts : {showSensor ? "Show" : "Hidden"}
                  </ButtonMT>
                </div>
              }
              columns={
                showSensor === true ? state.columns_sensor : state.columns
              }
              data={db_CafeHardware}
              style={{
                color: "white",
                background: "rgba(36, 43, 38, .5)",
                // backgroundColor: "rgba(0,0,0,.1)",
                // backgroundColor:
                //   "linear-gradient(90deg,rgba(25, 30, 26,.3) .25%, rgba(0, 0, 0,.5) .25%)",
                // backdropFilter: "blur( 11.0px )",
                // "&::webkit-backdrop-filter": {
                //   webkitBackdropFilter: "blur( 18.0px )",
                // },
              }}
              options={{
                exportButton: true,

                headerStyle: {
                  background: "rgba(54, 64, 56,.5)",
                  border: "none",
                },
                rowStyle: (rowData) => ({
                  backgroundColor: "rgba(25, 30, 26,.1)",

                  color:
                    rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
                  // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
                  fontSize: 12,
                }),
                filtering: true,
                grouping: true,
                exportAllData: true,
                pageSize: 100,
                pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                paginationType: "stepped",
                // actionsColumnIndex: -1
                // fixedColumns: {
                //     left: 1,
                // }
              }}
              onRowClick={(evt, selectedRow) => {
                // console.log(selectedRow.id);
                // setSelected_Item(selectedRow);
                // handleClickOpen_1_1();
                // setSelected_Input_OrderId(selectedRow.order_id);
                // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
                // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
              }}
              detailPanel={[
                {
                  tooltip: "Show Hardware Changes Log",
                  render: (rowData) => {
                    // console.log("rowData", rowData);
                    return (
                      <div>
                        <Comp_CafePC_PCHardware_Log_v2
                          cafe={rowData?.cafe}
                          pc_name={rowData?.PC_Name}
                        />
                      </div>
                    );
                  },
                },
              ]}
              actions={
                [
                  // {
                  //   icon: "save",
                  //   tooltip: "Save User",
                  //   onClick: (event, rowData) => {
                  //     // get_DB_CafeHardware_StatusLog_byCafePCName(
                  //     //   rowData?.cafe,
                  //     //   rowData?.PC_Name
                  //     // );
                  //     setSelectedRow(rowData);
                  //     setDialog_Show(true);
                  //     console.log("rowData", rowData);
                  //   },
                  // },
                ]
              }
              components={
                {
                  // Action: (props) => (
                  //   <Button
                  //     disabled
                  //     onClick={(event) => props.action.onClick(event, props.data)}
                  //     color="inherit"
                  //     variant="text"
                  //     style={{ textTransform: "none" }}
                  //     size="small"
                  //   >
                  //     🔎???
                  //   </Button>
                  // ),
                }
              }
            />
          </div>
        </DialogContent>
      </Dialog>

      <div
        // style={{ color: "gray" }}
        className="PCHardware_Container__pclist"
        onClick={() => {
          setOpened((o) => !o);
        }}
      >
        {opened ? "hide" : "show more pc ..."}
      </div>
      <Collapse in={opened}>
        {data?.map((x, i) => {
          return (
            <div
              className="PCHardware_Container_3sub2"
              onClick={() => {
                console.log("hw_name", hw_name);
                console.log("hw_value", hw_value);
                console.log("cafe", cafe);
                console.log("PC_Name", x?.PC_Name);
                get_DB_CafeHardware_byCafe(hw_name, hw_value, cafe, x?.PC_Name);
              }}
            >
              <div>{i + 1} </div>
              <div>{x?.PC_Name} </div>
              <div>{x?.count} </div>
            </div>
          );
        })}
      </Collapse>
    </>
  );
};
