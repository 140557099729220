import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

//ref fb login: https://www.youtube.com/watch?v=kEfe9u5F_L0&t=33s
//ref email login & logout: https://www.youtube.com/watch?v=9bXhf_TELP4

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpZk0EEvL5L6u70Mu6HDCmtgtmdM55u8U",
  authDomain: "eclipse-wms-react.firebaseapp.com",

  projectId: "eclipse-wms-react",
  storageBucket: "eclipse-wms-react.appspot.com",
  messagingSenderId: "738578716851",
  appId: "1:738578716851:web:d4f3ab1255ae08ef52f8c6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
