import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import "./Styles/global.css";
import "./Styles/cafepc_pcstatus.css";

// ------------------------------------------------- Database User 1/3
import axios from "axios";

// ------------------------------------------------- router 1/3
import { HashRouter, BrowserRouter, Route, Switch } from "react-router-dom";
// import { ProtectedRoute } from "./protected.route";

// ------------------------------------------------- firebase 1/3
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";

// ------------------------------------------------- Mui Theme 1/2
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// ------------------------------------------------- Page
import Page_Login from "./Page/Page_Login";

import Page_CafePC_Center from "./Page/Page_CafePC_Center";
import Page_CafePC_backup from "./Page/Page_CafePC_backup";
import Page_CafePC_PCStatus_backup from "./Page/Page_CafePC_PCStatus_backup";

import Page_CafeEvents from "./Page/Page_CafeEvents";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

function App() {
  // ------------------------------------------------- firebase 2/3
  // const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    dbData_SyncVersion();
    //   firebase data 3/3
    // const unregisterAuthObserver = firebase
    //   .auth()
    //   .onAuthStateChanged((user) => {
    //     setIsSignedIn(!!user);
    //   });

    // setTimeout(() => {
    //   authUser();
    // }, 1000);
    // setTimeout(() => {
    //   authUser();
    // }, 2000);
    // setTimeout(() => {
    //   authUser();
    // }, 5000);
    // setTimeout(() => {
    //   authUser();
    // }, 15000);
    // setTimeout(() => {
    //   authUser();
    // }, 30000);
  }, []);

  // ------------------------------------------------- Database User 2/3
  // const [DB_isSignedIn, setDB_isSignedIn] = useState(false);

  // const authUser = async () => {
  //   const DB_UserInfo = await axios
  //     .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_user`)
  //     .then((res) => {
  //       return res.data;
  //     });

  //   // console.log("###");
  //   // console.log(firebase.auth().currentUser);

  //   if (DB_UserInfo != undefined) {
  //     DB_UserInfo.map((DB_UserInfo_detials) => {
  //       if (firebase.auth().currentUser != null) {
  //         if (
  //           DB_UserInfo_detials.u_email === firebase.auth().currentUser.email
  //         ) {
  //           if (DB_UserInfo_detials.u_department.includes("awesomelist")) {
  //             setDB_isSignedIn(true);
  //             setU_department(DB_UserInfo_detials.u_department);
  //             setU_role(DB_UserInfo_detials.u_role);
  //           }
  //         }
  //       }
  //     });
  //   }
  // };

  const LoginTitle = () => {
    return (
      <>
        <br />
        <div style={{ textAlign: "center" }}>
          <img
            height="150px"
            src={`${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Web/Company_Logo.png`}
          ></img>
          <div style={{ fontSize: 50, color: "gold" }}>AWESOMELIST</div>
          <div style={{ fontSize: 15, color: "gold" }}>
            {localStorage.getItem("ver")}
          </div>
        </div>
      </>
    );
  };

  // get version sync
  const dbData_SyncVersion = async () => {
    const x = await axios
      .get(
        `
        ${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__version
        `
      )
      .then((res) => {
        if (localStorage.getItem("ver") === null) {
          localStorage.setItem("ver", res.data[0].value);

          window.location.reload();
        }
        if (localStorage.getItem("ver") !== res.data[0].value) {
          localStorage.setItem("ver", res.data[0].value);
          alert("New Update Available ! " + res.data[0].value);

          window.location.reload();
        }

        return res.data;
      });
  };

  // -------------------------------------------------  Context Parent 2/3
  const [u_department, setU_department] = useState("N.A");
  const [u_role, setU_role] = useState("N.A");

  // ------------------------------------------------- Mui Theme 2/2
  const theme = createMuiTheme({
    palette: {
      type: "dark",
    },
  });

  let isSignedIn = true;
  let DB_isSignedIn = true;

  return (
    <>
      {isSignedIn === true ? (
        DB_isSignedIn === true ? (
          <>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <HashRouter>
                  <Switch>
                    <Route exact path="/" component={Page_CafePC_Center} />
                    {/* <Route
                      exact
                      path="/Page_MyTask_Main"
                      component={Page_MyTask_Main}
                    /> */}

                    <Route
                      path="/Page_CafePC_Center"
                      component={Page_CafePC_Center}
                    />

                    <Route
                      path="/Page_CafePC_PCStatus_backup"
                      component={Page_CafePC_PCStatus_backup}
                    />
                    <Route path="/Page_Login" component={Page_Login} />
                    <Route
                      path="/Page_CafePC_backup"
                      component={Page_CafePC_backup}
                    />
                    <Route
                      path="/Page_CafeEvents"
                      component={Page_CafeEvents}
                    />
                  </Switch>
                </HashRouter>
              </BrowserRouter>
            </ThemeProvider>
          </>
        ) : (
          <>
            <div style={{ color: "gray" }}>Not allowed! Pls Contact Admin</div>
          </>
        )
      ) : (
        <>
          <LoginTitle />
          <Page_Login />
        </>
      )}
    </>
  );
}

export default App;
