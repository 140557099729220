import React, { useEffect, useState, useRef, useContext } from "react";
import { UserContext } from "../UserContext";

// import moment from "moment";
import axios from "axios";

import { Button } from "@material-ui/core";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function Check_FB_DB_Auth() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // -------------------------------- user auth from DB
  const [dbAuth_Status, setBDAuth_Status] = useState({
    Auth_Login: false,
  });
  // let BDAuth_Status = {
  //   Auth_Login: false,
  // };
  let dbAuth_Login_Obj = {
    u_email: "na",
  };

  const authUser = () => {
    // console.log("BDAuth_Login_Obj", BDAuth_Login_Obj);

    axios.get(`${REACT_APP_ECLIPSE_API}/get_eclipse_user`).then((res) => {
      // console.log("res", res.data);
      // return res?.data;

      res.data.forEach((res2) => {
        if (res2.u_email === dbAuth_Login_Obj?.u_email) {
          // console.log("res2", res2);
          if (
            (res2.u_department?.includes("awesomelist") ||
              res2.u_department?.includes("awesomelist")) &&
            res2.u_role?.includes("view")
          ) {
            // BDAuth_Status.Auth_Login = true;
            setBDAuth_Status({ ...{ Auth_Login: true }, ...res2 });
            // console.log("P1-1", { ...{ sAuth_Login: true }, ...res2 });
          }
        }
      });
    });
  };

  useEffect(() => {
    // console.log("P1-2", dbAuth_Status);
    contextValue.dbAuth_Status = dbAuth_Status;
  }, [dbAuth_Status.Auth_Login]);

  useEffect(() => {
    authUser();

    // console.log("useEffect - BDAuth_Status", dbAuth_Status);
  }, [dbAuth_Login_Obj]);

  return [dbAuth_Status, dbAuth_Login_Obj];
}
