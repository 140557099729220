import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "@mantine/core";

import { ResponsiveBar } from "@nivo/bar";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;
const Chart_Bar = ({ p_data }) => {
  // console.log("Chart_Bar,props", p_data);

  // console.log("cafe", p_data?.cafe);
  // console.log("pc_name", p_data?.pc_name);

  const [db_Chart, setDb_Chart] = useState([]);

  const get_DB_ALLSensor__byCafePcName = async (
    cafe,
    PC_Name,
    sample_amount
  ) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_status_log__allsensor__bycafepcname`,
        {
          cafe,
          PC_Name,
          sample_amount,
        }
      )
      .then((res) => {
        setDb_Chart(res.data);

        console.log("get_DB_ALLSensor__byCafePcName");
      });
  };

  // ---------------------------------- global variable
  const [local_sample_amount, setLocal_sample_amount] = useState(
    p_data?.sample_amount
  );

  const getData = (local_sample_amount) => {
    get_DB_ALLSensor__byCafePcName(
      p_data?.cafe,
      p_data?.PC_Name,
      local_sample_amount
    );
  };

  useEffect(() => {
    getData(local_sample_amount);
  }, []);

  const CharBar = ({ data, Chart_Colors }) => {
    // console.log("data", data);
    // console.log("Chart_Colors", Chart_Colors);
    return (
      <div
        style={{
          height: "15px",
          width: "100%",
          // backgroundColor: "white",
          color: "black",
        }}
      >
        <ResponsiveBar
          data={data}
          keys={[
            "0_40",
            "40_50",
            "50_60",
            "60_70",
            "70_80",
            "80_90",
            "90_100",
            "100above",
          ]}
          indexBy="item_name"
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          padding={0}
          layout="horizontal"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: Chart_Colors }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "fries",
              },
              id: "dots",
            },
            {
              match: {
                id: "sandwich",
              },
              id: "lines",
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", "0.8"]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "country",
            legendPosition: "middle",
            legendOffset: 32,
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "food",
            legendPosition: "middle",
            legendOffset: -40,
            truncateTickAt: 0,
          }}
          enableGridY={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          // legends={[
          //   {
          //     dataFrom: "keys",
          //     anchor: "bottom-right",
          //     direction: "column",
          //     justify: false,
          //     translateX: 120,
          //     translateY: 0,
          //     itemsSpacing: 2,
          //     itemWidth: 100,
          //     itemHeight: 20,
          //     itemDirection: "left-to-right",
          //     itemOpacity: 0.85,
          //     symbolSize: 20,
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemOpacity: 1,
          //         },
          //       },
          //     ],
          //   },
          // ]}
          animate={false}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={(e) =>
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          }
        />
      </div>
    );
  };

  return (
    <>
      <div className="FlexRow">
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 1);
          }}
        >
          1h
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 2);
          }}
        >
          2h
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 4);
          }}
        >
          4h
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 8);
          }}
        >
          8h
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 16);
          }}
        >
          16h
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 24);
          }}
        >
          1d
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 24 * 2);
          }}
        >
          2d
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 24 * 4);
          }}
        >
          4d
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 24 * 7 * 1);
          }}
        >
          1w
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 24 * 7 * 2);
          }}
        >
          2w
        </Button>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            getData(4 * 24 * 7 * 4);
          }}
        >
          1m
        </Button>
      </div>
      {db_Chart.length > 0 ? (
        <>
          <CharBar data={db_Chart[0]} Chart_Colors="yellow_orange_red" />
          <CharBar data={db_Chart[1]} Chart_Colors="yellow_orange_red" />
          <CharBar data={db_Chart[2]} Chart_Colors="blue_purple" />
          <CharBar data={db_Chart[3]} Chart_Colors="yellow_orange_red" />
          <CharBar data={db_Chart[4]} Chart_Colors="yellow_orange_red" />
          <CharBar data={db_Chart[5]} Chart_Colors="purple_red" />
        </>
      ) : (
        "loading..."
      )}
    </>
  );
};

export default Chart_Bar;
