import React, { useEffect, useState, useRef, useContext } from "react";
import { UserContext } from "../UserContext";

import useFirebase from "../Hooks/useFirebase";
import useDBAuth from "../Hooks/useDBAuth";

import {
  Grid,
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";

// -------------------------------------------------------------------------------------MUI - Tab  1/4
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// -------------------------------------------------------------------------------------MUI - Tab  2/4
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        window.location.assign(props.href);
      }}
      {...props}
    />
  );
}

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

//-------------------------------------------------------------------------- main return()
export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);
  // --------------------------------------- auth User
  const [firebase_Status, firebase_SignOut, firebase_LoginUI] = useFirebase();
  const [dbAuth_Status, dbAuth_Login_Obj] = useDBAuth();

  const renderLimit = useRef(2);
  if (firebase_Status?.login_Status === true && renderLimit.current > 0) {
    // console.log("P1");
    dbAuth_Login_Obj.u_email = firebase_Status?.userInfo_Email;
    renderLimit.current--;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // -------------------------------------------------------------------------------------MUI - Tab  3/4
  const handleChange = (event, newValue) => {
    setTabIndexValue(newValue);
  };

  const useStyles = makeStyles({
    tab2_GBColor: {
      background: "linear-gradient(45deg, #ffeb0f   30%, #f3ff0f 90%)",
      borderRadius: 3,
      border: 1,
      color: "black",
      height: 48,
      padding: "0 0px",
      boxShadow: "0 3px 30px 2px rgba(255, 231, 15, .3)",
    },
  });
  const classes = useStyles();
  const [tabIndexValue, setTabIndexValue] = useState(0);

  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 200,
        width: "100%",
        background: "linear-gradient(45deg, #D3CCE3  30%,#E9E4F0 90%)",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  //  Get Date from DB -----------------------------------------------------------------------------------------

  // ------------------ useEffect
  useEffect(() => {}, []);

  return (
    <>
      <>
        <AppBar position="sticky" style={{ marginBottom: "-3rem" }}>
          <div>
            <div
              // className={classes.tab2_GBColor}
              className="nav1_theme"
            >
              <StyledTabs
                value={tabIndexValue}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <LinkTab
                  label={
                    <>
                      <div style={{ display: "flex" }}>
                        <Typography
                          // variant="caption"
                          // display="block"
                          style={{
                            textAlign: "center",
                            marginRight: "10px",
                          }}
                        >
                          CAFE PC
                        </Typography>
                      </div>
                    </>
                  }
                  href="#/Page_CafePC_Center"
                  {...a11yProps(1)}
                />
                <LinkTab
                  label={<div>Cafe PC (backup)</div>}
                  href="#/Page_CafePC_backup"
                  {...a11yProps(2)}
                />
                <LinkTab
                  label={<div>Cafe Events</div>}
                  href="#/Page_CafeEvents"
                  {...a11yProps(3)}
                />
                <div
                  className="FlexRow"
                  style={{ position: "absolute", right: 1 }}
                >
                  <div
                    style={{
                      fontSize: "1.6rem",
                      fontWeight: 555,
                      marginRight: "1rem",
                    }}
                  >
                    AweSoMe List
                  </div>

                  <Button
                    onClick={() => {
                      // signOut(authentication);
                      firebase_SignOut();
                    }}
                  >
                    <div className="FlexRow">
                      <img
                        src={firebase_Status?.userInfo_PhotoURL}
                        height="25rem"
                        style={{ borderRadius: "20rem", marginRight: "1rem" }}
                      />
                      <div>LOGOUT</div>
                    </div>
                  </Button>
                </div>
              </StyledTabs>
            </div>
          </div>
        </AppBar>
        <TabPanel value={tabIndexValue} index={0}></TabPanel>
        <TabPanel value={tabIndexValue} index={1}></TabPanel>
        <TabPanel value={tabIndexValue} index={2}></TabPanel>
      </>
    </>
  );
}
