import React, { useEffect, useState } from "react";
import {
  Calendar,
  DatePicker,
  DateRangePicker,
  DateRangePickerValue,
} from "@mantine/dates";

const Comp_Calendar = ({ CB_selectedDate }) => {
  // console.log("props", );
  const [value, setValue] = useState(Date.now());

  const formatDate = (dateString) => {
    let date = new Date(dateString);
    let formattedDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return formattedDate;
  };

  useEffect(() => {
    CB_selectedDate(formatDate(value));
  }, [value]);

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        {/* {value !== null ? value?.getDate() : "null value"} */}
        {/* {console.log("value", formatDate(value))} */}
        <Calendar
          label="Book hotel"
          placeholder="Pick dates range"
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
        />
      </div>
    </>
  );
};

export default Comp_Calendar;
